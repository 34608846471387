import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Paginator from "../Paginator";
import "./DashboardTable.scss";

const ROWS_PER_PAGE = 5;

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#74A2D3",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
    position: "relative",
    width: "100%",
    tableLayout: "fixed",
  },
  dashboardSubmittedTitle: {
    color: "#74A2D3",
  },
  addPatientBtn: {
    margin: "0 2rem",
    backgroundColor: "#74A2D3",
    borderRadius: "3rem",
  },
  addPatientIcon: {
    color: "#ffffff",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: fade(theme.palette.common.white, 0.15),
    // "&:hover": {
    //   backgroundColor: fade(theme.palette.common.white, 0.25),
    // },
    backgroundColor: "#eeeeee",

    margin: ".2rem 0",
    width: "30rem",
    // width: "100%",
    // [theme.breakpoints.up("sm")]: {
    //   marginLeft: theme.spacing(1),
    //   width: "auto",
    // },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "30rem",
    border: "1px solid #eeeeee",
    borderRadius: ".2rem",
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 2),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create("width"),
    width: "100%",

    // [theme.breakpoints.up("sm")]: {
    //   width: "12ch",
    //   "&:focus": {
    //     width: "20ch",
    //   },
    // },
  },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: 200,
  // },
  // root: {
  //   boxShadow: "5px 10px 8px #888888",
  //   position: "fixed",
  //   margin: "10rem 5rem 0 5rem",
  //   zIndex: 1,
  //   width: "75%",
  // },
}));

function DashboardTable({
  handleClickOpen,
  onHandleNameClick,
  notAssignedPatientList,
  assignedPatientList,
  isLoading,
}) {
  const classes = useStyles();

  const [firstTablePage, setFirstTablePage] = useState(1);
  const [secondTablePage, setSecondTablePage] = useState(1);
  const [searchSubmittedPatients, setSearchSubmittedPatients] = useState("");
  const [searchAssignedPatients, setSearchAssignedPatients] = useState("");

  const handleSearchSubmitted = (e) => {
    setSearchSubmittedPatients(e.target.value);
    setFirstTablePage(1);
  };

  const handleSearchAssigned = (e) => {
    setSearchAssignedPatients(e.target.value);
    setFirstTablePage(1);
  };

  const patientSubmittedData = useMemo(() => {
    let unassignedPatientList = notAssignedPatientList;

    var searchTerm = searchSubmittedPatients;
    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();

      unassignedPatientList = unassignedPatientList.filter((p) => {
        var firstName = p.patient_firstname?.toLowerCase() || "";
        var lastName = p.patient_lastname?.toLowerCase() || "";

        if (
          firstName.includes(searchTerm) ||
          lastName.includes(searchTerm) ||
          (firstName + " " + lastName).includes(searchTerm)
        ) {
          return p;
        }
      });
    }

    return unassignedPatientList.slice(
      (firstTablePage - 1) * ROWS_PER_PAGE,
      (firstTablePage - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE
    );
  }, [notAssignedPatientList, searchSubmittedPatients, firstTablePage]);

  const patientAssignedData = useMemo(() => {
    let assignedToPatientList = assignedPatientList;

    var searchTerm = searchAssignedPatients;
    if (searchTerm) {
      searchTerm = searchTerm.toLowerCase();

      assignedToPatientList = assignedToPatientList.filter((p) => {
        var firstName = p.patient_firstname?.toLowerCase() || "";
        var lastName = p.patient_lastname?.toLowerCase() || "";

        if (
          firstName.includes(searchTerm) ||
          lastName.includes(searchTerm) ||
          (firstName + " " + lastName).includes(searchTerm)
        ) {
          return p;
        }
      });
    }

    return assignedToPatientList.slice(
      (secondTablePage - 1) * ROWS_PER_PAGE,
      (secondTablePage - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE
    );
  }, [assignedPatientList, searchAssignedPatients, secondTablePage]);

  return (
    <>
      <div className="dashboard-table-wrap">
        {/* <div className="dashboard-submitted-wrap">
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.dashboardSubmittedTitle}
          >
            CLIENTS SUBMITTED BY CLINIC
          </Typography>
          <Button
            variant="contained"
            // color="secondary"
            size="large"
            className={classes.addPatientBtn}
          >
            <AddCircleOutlineIcon className={classes.addPatientIcon} />
          </Button>
        </div> */}
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search by patient # or name"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearchSubmitted}
          />
        </div>
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Patient #</StyledTableCell>
                <StyledTableCell align="center">Pledge # </StyledTableCell>
                <StyledTableCell align="center">Name </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patientSubmittedData.map((item) => (
                <StyledTableRow key={item.pledge_number}>
                  <StyledTableCell align="center" className="cell-msg">
                    {item.status}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {/* {item.patient_number} */}
                    {item.patient_id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {item.pledge_number}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    className="cell-actions"
                    onClick={onHandleNameClick}
                    id={item.patient_id}
                  >
                    {item.patient_firstname} {item.patient_lastname}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paginator
          sourceData={notAssignedPatientList}
          countPerPage={ROWS_PER_PAGE}
          currentPage={firstTablePage}
          changePage={(newPage) => setFirstTablePage(newPage)}
        />

        <div className="dashboard-submitted-wrap">
          <Typography
            variant="h5"
            color="textSecondary"
            className={classes.dashboardSubmittedTitle}
          >
            PATIENTS ASSIGNED TO CLINIC
          </Typography>
        </div>

        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search by patient # or name"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={handleSearchAssigned}
          />
        </div>
        <TableContainer component={Paper} className={classes.table}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Patient #</StyledTableCell>
                <StyledTableCell align="center">Pledge # </StyledTableCell>
                <StyledTableCell align="center">Name </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                // assignedPatientList
                //   .slice(
                //     (secondTablePage - 1) * ROWS_PER_PAGE,
                //     (secondTablePage - 1) * ROWS_PER_PAGE + ROWS_PER_PAGE
                //   )
                //   .filter((patient) =>
                //     Object.values(patient).join(" ").match(searchAssignedPatients)
                //   )
                patientAssignedData.map((item) => (
                  <StyledTableRow key={item.pledge_number}>
                    <StyledTableCell align="center" className="cell-msg">
                      {item.status}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.patient_id}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {item.pledge_number}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      className="cell-actions"
                      onClick={onHandleNameClick}
                      id={item.patient_id}
                    >
                      {item.patient_firstname} {item.patient_lastname}
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Paginator
        sourceData={assignedPatientList}
        countPerPage={ROWS_PER_PAGE}
        currentPage={secondTablePage}
        changePage={(newPage) => setSecondTablePage(newPage)}
      />
    </>
  );
}

DashboardTable.propTypes = {
  // onClickOpen: PropTypes.func,
  notAssignedPatientList: PropTypes.array,
  assignedPatientList: PropTypes.array,
  onHandleNameClick: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DashboardTable;
