import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
// import { CheckboxWithLabel } from "formik-material-ui";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import moment from "moment";

// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from "@material-ui/pickers";
// import "date-fns";
// import DateFnsUtils from "@date-io/date-fns";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import "./PatientForm.scss";

import PropTypes from "prop-types";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#74A2D3",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  patientFormToolbar: {
    backgroundColor: "#74A2D3",
  },
  formTitle: {
    color: "#74A2D3",
    margin: "1rem",
  },
  outlinedInput: {
    margin: "0 .5rem",
  },
  notesText: {
    width: "50%",
    margin: "1rem",
  },
  formBtn: {
    width: "12rem",
    backgroundColor: "#74A2D3",
    color: "#ffffff",
    margin: "1rem",
    "&:hover": {
      backgroundColor: "#0085c7",
    },
  },
  field: {
    margin: ".5rem 1rem",
    width: "15rem",
  },
  select: {
    margin: ".5rem 1rem",
    width: "15rem",
  },
  caseHelper: {
    color: "#74A2D3",
    margin: "0 1rem",
    fontSize: ".7rem",
  },
  helperText: {
    color: "red",
  },
  statesFormControl: {
    margin: ".5rem 1rem",
    width: "15rem",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const schema = yup.object().shape({
  facilityIdNumber: yup.number().required("* Facility ID  is a required field"),
  patientFirstName: yup.string().required("* First name  is a required field"),
  patientLastName: yup.string().required("* Last name  is a required field"),
  // patientPhoneNumber: yup
  //   .string()
  //   .required("* Phone number is a required field"),
  // patientStateOfResidence: yup
  //   .string()
  //   .required("* State  is a required field"),

  patientApptWksLmp: yup
    .string()
    .required("* EGA on date of abortion is a required field"),
  patientDateOfAbortionDate: yup
    .string()
    .required("* Date of abortion is a required field"),
  patientFamilySize: yup.string().required("* Family size is a required field"),

  patientGrossMonthly: yup
    .string()
    .required("* Gross monthly income is a required field"),
  patientProcedurePrice: yup
    .string()
    .required("* Procedure Price is a required field"),
  patientContributionNumber: yup
    .string()
    .required("* Patient Contribution is a required field"),
  patientTotalOtherFunds: yup
    .string()
    .required("* Total From Other Funds is a required field"),
  patientAmountOfPledge: yup
    .string()
    .required("* Amount of Pledge is a required field"),
});

const PatientForm = ({
  openForm,
  handleClickClose,
  patientFormField,
  handleInputChange,
  handleSaveForm,
  handleUpdateForm,
  saveButtonState,
  handlePatientRaceChange,
  patientRaces,
  patientDropDown,
  handleMedicalAbortionChange,
  handlePatientMedicaidChange,
  handlePatientCoPayChange,
  handlePatientFundException,
  fundExceptionArr,
  handleRaceChange,
  selectedRaces,
  handlePatientRelationshipChange,
  relationshipArr,
  handlePatientStateChange,
  handlePreAbortionServiceApprovedChange,
  // error,
}) => {
  const classes = useStyles();

  // const [selectedDate, setSelectedDate] = useState(
  //   new Date("2014-08-18T21:11:54")
  // );

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  const [submitAction, setSubmitAction] = useState("");
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });
  // const onSubmit = (data) => console.log(data);
  // const [race, setRace] = useState(" ");

  const onSubmit = (data, e) => {
    // handleSaveForm();
    // console.log(data.target.name);
    if (submitAction === "submit") {
      handleSaveForm();
    }

    if (submitAction === "update") {
      handleUpdateForm();
    }
  };
  // console.log(errors);

  const patientRacesArr = [
    "American Indian or Alaskan Native",
    "Asian",
    "Black or African American",
    "Hispanic or Latinx",
    "Native Hawaiian or Pacific Islander",
    "White",
    "Other",
  ];

  const usStates = [
    { name: "ALABAMA", abbreviation: "AL" },
    { name: "ALASKA", abbreviation: "AK" },
    { name: "AMERICAN SAMOA", abbreviation: "AS" },
    { name: "ARIZONA", abbreviation: "AZ" },
    { name: "ARKANSAS", abbreviation: "AR" },
    { name: "CALIFORNIA", abbreviation: "CA" },
    { name: "COLORADO", abbreviation: "CO" },
    { name: "CONNECTICUT", abbreviation: "CT" },
    { name: "DELAWARE", abbreviation: "DE" },
    { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
    { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
    { name: "FLORIDA", abbreviation: "FL" },
    { name: "GEORGIA", abbreviation: "GA" },
    { name: "GUAM", abbreviation: "GU" },
    { name: "HAWAII", abbreviation: "HI" },
    { name: "IDAHO", abbreviation: "ID" },
    { name: "ILLINOIS", abbreviation: "IL" },
    { name: "INDIANA", abbreviation: "IN" },
    { name: "IOWA", abbreviation: "IA" },
    { name: "KANSAS", abbreviation: "KS" },
    { name: "KENTUCKY", abbreviation: "KY" },
    { name: "LOUISIANA", abbreviation: "LA" },
    { name: "MAINE", abbreviation: "ME" },
    { name: "MARSHALL ISLANDS", abbreviation: "MH" },
    { name: "MARYLAND", abbreviation: "MD" },
    { name: "MASSACHUSETTS", abbreviation: "MA" },
    { name: "MICHIGAN", abbreviation: "MI" },
    { name: "MINNESOTA", abbreviation: "MN" },
    { name: "MISSISSIPPI", abbreviation: "MS" },
    { name: "MISSOURI", abbreviation: "MO" },
    { name: "MONTANA", abbreviation: "MT" },
    { name: "NEBRASKA", abbreviation: "NE" },
    { name: "NEVADA", abbreviation: "NV" },
    { name: "NEW HAMPSHIRE", abbreviation: "NH" },
    { name: "NEW JERSEY", abbreviation: "NJ" },
    { name: "NEW MEXICO", abbreviation: "NM" },
    { name: "NEW YORK", abbreviation: "NY" },
    { name: "NORTH CAROLINA", abbreviation: "NC" },
    { name: "NORTH DAKOTA", abbreviation: "ND" },
    { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
    { name: "OHIO", abbreviation: "OH" },
    { name: "OKLAHOMA", abbreviation: "OK" },
    { name: "OREGON", abbreviation: "OR" },
    { name: "PALAU", abbreviation: "PW" },
    { name: "PENNSYLVANIA", abbreviation: "PA" },
    { name: "PUERTO RICO", abbreviation: "PR" },
    { name: "RHODE ISLAND", abbreviation: "RI" },
    { name: "SOUTH CAROLINA", abbreviation: "SC" },
    { name: "SOUTH DAKOTA", abbreviation: "SD" },
    { name: "TENNESSEE", abbreviation: "TN" },
    { name: "TEXAS", abbreviation: "TX" },
    { name: "UTAH", abbreviation: "UT" },
    { name: "VERMONT", abbreviation: "VT" },
    { name: "VIRGIN ISLANDS", abbreviation: "VI" },
    { name: "VIRGINIA", abbreviation: "VA" },
    { name: "WASHINGTON", abbreviation: "WA" },
    { name: "WEST VIRGINIA", abbreviation: "WV" },
    { name: "WISCONSIN", abbreviation: "WI" },
    { name: "WYOMING", abbreviation: "WY" },
  ];

  const displaySave = () => {
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.patientFormToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClickClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Patient Detail Form
          </Typography>
          <Button
            autoFocus
            color="inherit"
            type="submit"
            onClick={() => {
              setSubmitAction("submit");
            }}
            name="submit"
          >
            Submit
          </Button>
        </Toolbar>
      </AppBar>
    );
  };

  const displayUpdate = () => {
    return (
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.patientFormToolbar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClickClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Patient Detail Form
          </Typography>
          <Button
            type="submit"
            autoFocus
            color="inherit"
            onClick={() => {
              setSubmitAction("update");
            }}
            name="update"
          >
            update
          </Button>
        </Toolbar>
      </AppBar>
    );
  };

  const updateBtn = () => {
    return (
      <Button
        type="submit"
        className={classes.formBtn}
        autoFocus
        color="inherit"
        onClick={() => {
          setSubmitAction("update");
        }}
      >
        Update
      </Button>
    );
  };

  const submitBtn = () => {
    return (
      <Button
        type="submit"
        className={classes.formBtn}
        autoFocus
        color="inherit"
        onClick={() => {
          setSubmitAction("submit");
        }}
      >
        Submit
      </Button>
    );
  };

  const calculateDefaultRaces = () => {
    const raceArr = [];

    if (patientFormField.patientRacesAsian === true) {
      raceArr.push(patientRacesArr[1]);
    }

    if (patientFormField.patientRacesBlackAmerican === true) {
      raceArr.push(patientRacesArr[2]);
    }

    if (patientFormField.patientRacesWhite === true) {
      raceArr.push(patientRacesArr[5]);
    }

    if (patientFormField.patientRacesNativeAmerican === true) {
      raceArr.push(patientRacesArr[4]);
    }

    if (patientFormField.patientRacesLatin === "1") {
      raceArr.push(patientRacesArr[3]);
    }

    if (patientFormField.patientRacesAlaskin === true) {
      raceArr.push(patientRacesArr[0]);
    }

    if (patientFormField.patientRacesOther === true) {
      raceArr.push(patientRacesArr[6]);
    }

    return raceArr;
  };

  return (
    <div className="form-container">
      {/* <Button onClick={handleClickOpen}>test</Button> */}
      <Dialog
        fullScreen
        open={openForm}
        onClose={handleClickClose}
        TransitionComponent={Transition}
      >
        {/* <AppBar className={classes.appBar}>
          <Toolbar className={classes.patientFormToolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClickClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Patient Detail Form
            </Typography>
            <Button autoFocus color="inherit" onClick={handleSaveForm}>
              save
            </Button>
          </Toolbar>
        </AppBar> */}

        {/* <div className="info-wraper"> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          {saveButtonState ? displaySave() : displayUpdate()}
          <Typography variant="h6" className={classes.formTitle}>
            Patient Info
          </Typography>
          <TextField
            id="facilityIdNumber"
            name="facilityIdNumber"
            label="Facility ID*"
            className={classes.field}
            // onChange={handleInputChange}
            value={patientFormField.facilityIdNumber || ""}
            // error={error.facilityIdNumberError}
            // helperText={error.facilityIdNumberErrorText}
            // required
            helperText={errors.facilityIdNumber?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          <TextField
            id="patientFirstName"
            name="patientFirstName"
            label="First Name*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientFirstName || ""}
            // error={errors.patientFirstName?.message ? true : false}
            helperText={errors.patientFirstName?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            // required
            inputRef={register}
          />
          {/* <br /> */}
          <TextField
            id="patientLastName"
            name="patientLastName"
            label="Last Name*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientLastName || ""}
            // error={error.LastNameError}
            // helperText={error.LastNameErrorText}
            // required
            helperText={errors.patientLastName?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          <TextField
            id="patientPhoneNumber"
            name="patientPhoneNumber"
            label="Patient Phone Number"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientPhoneNumber || ""}
            // error={
            //   error.patientPhoneNumberErrorText === "" ? false : true
            // }
            // helperText={error.patientPhoneNumberErrorText}
            // required
            // helperText={errors.patientPhoneNumber?.message}
            // FormHelperTextProps={{
            //   className: classes.helperText,
            // }}
            // inputRef={register}
          />
          <br />
          <TextField
            id="patientResidenceZipcode"
            name="patientResidenceZipcode"
            label="Residence  Zipcode"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientResidenceZipcode || ""}
          />
          <TextField
            id="patientCity"
            name="patientCity"
            label="City"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientCity || ""}
          />
          {/* <TextField
            id="patientStateOfResidence"
            name="patientStateOfResidence"
            label="State of Residence*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientStateOfResidence || ""}
            // error={
            //   error.patientStateOfResidenceErrorText === "" ? false : true
            // }
            // helperText={error.patientStateOfResidenceErrorText}
            // required
            helperText={errors.patientStateOfResidence?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          /> */}
          <TextField
            id="patientStateOfResidence"
            name="patientStateOfResidence"
            label="State of Residence*"
            select
            className={classes.field}
            onChange={handlePatientStateChange}
            value={patientFormField.patientStateOfResidence || ""}
            // error={
            //   error.patientStateOfResidenceErrorText === "" ? false : true
            // }
            // helperText={error.patientStateOfResidenceErrorText}
            // required
            helperText={errors.patientStateOfResidence?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            // inputRef={register}
          >
            {usStates.map((state) => (
              <MenuItem value={state.abbreviation}>
                {state.abbreviation}
              </MenuItem>
            ))}
          </TextField>
          {/* <FormControl className={classes.statesFormControl}>
            <InputLabel id="demo-simple-select-label">
              State of Residence*
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patientStateOfResidence"
              value={patientFormField.patientStateOfResidence || ""}
              onChange={handlePatientStateChange}
            >
              {usStates.map((state) => (
                <MenuItem value={state.abbreviation}>
                  {state.abbreviation}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <TextField
            id="patientFamilySize"
            name="patientFamilySize"
            label="Family Size*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientFamilySize || ""}
            helperText={errors.patientFamilySize?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          <br />
          <TextField
            id="patientGrossMonthly"
            name="patientGrossMonthly"
            label="Gross Monthly Income*"
            type="number"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientGrossMonthly || ""}
            helperText={errors.patientGrossMonthly?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>$</div>
                </InputAdornment>
              ),
            }}
          />
          <br />
          <Typography variant="h6" className={classes.formTitle}>
            Procedure info
          </Typography>
          <TextField
            id="patientApptWksLmp"
            name="patientApptWksLmp"
            // label="Appt Wks Lmp"
            label=" EGA on Date of Abortion*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientApptWksLmp || ""}
            helperText={errors.patientApptWksLmp?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          {/* <TextField
            id="patientGestage"
            name="patientGestage"
            label=" EGA on Date of Abortion*"
            type="date"
            // defaultValue="2017-05-24"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientGestage || ""}
            helperText={errors.patientGestage?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              // margin="normal"
              id="patientGestage"
              name="patientGestage"
              label="EGA on Date of Abortion*"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider> */}

          {/* <TextField
            id="patientDateOfAbortionDate"
            name="patientDateOfAbortionDate"
            label="Date of Abortion*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientDateOfAbortionDate || ""}
            // error={
            //   error.patientDateOfAbortionDateErrorText === ""
            //     ? false
            //     : true
            // }
            // helperText={error.patientDateOfAbortionDateErrorText}
            // required
            helperText={errors.patientDateOfAbortionDate?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          /> */}
          <TextField
            id="patientDateOfAbortionDate"
            name="patientDateOfAbortionDate"
            label="Date of Abortion*"
            type="date"
            // defaultValue="2017-05-24"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientDateOfAbortionDate || ""}
            helperText={errors.patientDateOfAbortionDate?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="patientProcedurePrice"
            name="patientProcedurePrice"
            label="Procedure Price*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientProcedurePrice || ""}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>$</div>
                </InputAdornment>
              ),
            }}
            helperText={errors.patientProcedurePrice?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          <TextField
            id="patientContributionNumber"
            name="patientContributionNumber"
            label="Patient Contribution*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientContributionNumber || ""}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>$</div>
                </InputAdornment>
              ),
            }}
            helperText={errors.patientContributionNumber?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />

          <br />
          <TextField
            id="patientTotalOtherFunds"
            name="patientTotalOtherFunds"
            label="Total From Other Funds*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientTotalOtherFunds || ""}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>$</div>
                </InputAdornment>
              ),
            }}
            helperText={errors.patientTotalOtherFunds?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          <TextField
            id="patientOtherFundName"
            name="patientOtherFundName"
            label="Describe Non-NAF Aid"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientOtherFundName || ""}
          />
          <TextField
            id="patientAmountOfPledge"
            name="patientAmountOfPledge"
            label="Amount of Pledge*"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientAmountOfPledge || ""}
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div>$</div>
                </InputAdornment>
              ),
            }}
            helperText={errors.patientAmountOfPledge?.message}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            inputRef={register}
          />
          <FormControl
            className={classes.select}

            // name="patientRelationshipStatus"
            // placeholder="select options"
          >
            <InputLabel id="demo-simple-select-label">
              Co-Pay Exception
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patientCopayException"
              // defaultValue=""
              value={patientFormField.patientCopayException || ""}
              onChange={handlePatientCoPayChange}
            >
              {patientDropDown.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <TextField
            id="patientFundException"
            name="patientFundException"
            label="Fund Exception"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientFundException}
          /> */}
          <FormControl
            className={classes.select}

            // name="patientRelationshipStatus"
            // placeholder="select options"
          >
            <InputLabel id="demo-simple-select-label">
              Fund Exception
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patientFundException"
              value={patientFormField.patientFundException || ""}
              onChange={handlePatientFundException}
            >
              {fundExceptionArr.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Field
                  id="patientCopayException"
                  component={TextField}
                  name="patientCopayException"
                  label="Co-Pay Exception"
                  className={classes.field}
                  onChange={handleInputChange}
                  value={patientFormField.patientCopayException}
                /> */}
          <TextField
            id="patientExceptionApproval"
            name="patientExceptionApproval"
            label="Exception Approval"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientExceptionApproval || ""}
          />

          <TextField
              id="patient_pre_abortion_services_amt"
              name="patient_pre_abortion_services_amt"
              label="PRE-ABORTION SERVICES"
              className={classes.field}
              onChange={handleInputChange}
              value={patientFormField.patient_pre_abortion_services_amt || ""}
              type="number"
              InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                        <div>$</div>
                      </InputAdornment>
                  ),
              }}
              inputRef={register}
          />

          <FormControl style={{ marginTop: "20px"}}>
            <FormControlLabel
                label="PRE-ABORTION SERVICES APPROVED"
                labelPlacement="start"
                control={
                  <Checkbox
                      id="patient_pre_abortion_services_approved"
                      checkedIcon={checkedIcon}
                      style={{ color: "#74A2D3"}}
                      onChange={handlePreAbortionServiceApprovedChange}
                      checked={patientFormField.patient_pre_abortion_services_approved}  />
                }

            />

          </FormControl>

          <br />
          <FormControl
            className={classes.select}

            // name="patientRelationshipStatus"
            // placeholder="select options"
          >
            <InputLabel id="demo-simple-select-label">
              Medical Abortion
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="medicalAbortion"
              value={patientFormField.medicalAbortion || ""}
              onChange={handleMedicalAbortionChange}
            >
              {patientDropDown.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>





          <br />
          <Typography variant="h6" className={classes.formTitle}>
            Demographics
          </Typography>
          <FormControl
            className={classes.select}

            // name="patientRelationshipStatus"
            // placeholder="select options"
          >
            <InputLabel id="demo-simple-select-label">
              Patient On Medicaid
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patientOnMediciad"
              value={patientFormField.patientOnMediciad || ""}
              onChange={handlePatientMedicaidChange}
            >
              {patientDropDown.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <TextField
            id="patientPreabortionApproved"
            name="patientPreabortionApproved"
            label="Pre-abortion Services Approved"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientPreabortionApproved}
          />
          <TextField
            id=" patientPreAbortionAmount"
            name=" patientPreAbortionAmount"
            label="Pre-abortion Services Amount"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientPreAbortionAmount}
          /> */}
          {/* <TextField
            id="patientGestage"
            name="patientGestage"
            label="Gestage"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientGestage}
          /> */}
          {/* <Field
                  id="patientOnMediciad"
                  component={TextField}
                  name="patientOnMediciad"
                  label="Patient On Mediciad"
                  className={classes.field}
                  onChange={handleInputChange}
                  value={patientFormField.patientOnMediciad}
                /> */}
          {/* <br /> */}
          {/* <TextField
            id="patientRelationshipStatus"
            name="patientRelationshipStatus"
            label="Relationship Status"
            className={classes.field}
            onChange={handleInputChange}
            value={patientFormField.patientRelationshipStatus}
          /> */}
          <FormControl
            className={classes.select}

            // name="patientRelationshipStatus"
            // placeholder="select options"
          >
            <InputLabel id="demo-simple-select-label">
              Relationship Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patientRelationshipStatus"
              value={patientFormField.patientRelationshipStatus || ""}
              onChange={handlePatientRelationshipChange}
            >
              {relationshipArr.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Field
                  id="patientRace"
                  component={Select}
                  name="patientRace"
                  label="Race"
                  className={classes.field}

                  // onChange={handleInputChange}
                  // value={patientFormField.patientRaces}
                /> */}
          {/* <Typography variant="h6" className={classes.formTitle}>
                  Races
                </Typography> */}
          {/* <Autocomplete
            // value={patientFormField.patientRaces[0]}
            id="combo-box-demo"
            options={patientRacesArr}
            getOptionLabel={(option) => option}
            style={{ width: 400 }}
            renderInput={(params) => (
              <TextField {...params} label="Race" variant="outlined" />
            )}
            onChange={handleRaceChange}
          /> */}
          <Autocomplete
            className={classes.field}
            multiple
            id="checkboxes-tags"
            options={patientRacesArr}
            // defaultValue={patientRaces.find((option, state) => v.label[0])}
            value={calculateDefaultRaces()}
            disableCloseOnSelect
            onChange={handleRaceChange}
            getOptionLabel={(option) => `${option}`}
            renderOption={(option, state) => {
              // console.log(option);
              // console.log(state);

              // const selectRaceIndex = selectedRaces.findIndex(
              //   (race) => race.toLowerCase() === "all"
              // );
              // if (selectRaceIndex > -1) {
              //   state.selected = true;
              // }
              return (
                <React.Fragment>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ color: "#74A2D3" }}
                    checked={state.selected}
                  />
                  {option}
                </React.Fragment>
              );
            }}
            style={{ width: 400 }}
            renderInput={(params) => {
              // console.log(params);

              return (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Race"
                  placeholder="Selected"
                />
              );
            }}
          />
          {/* <FormControl
            className={classes.select}

            // name="patientRelationshipStatus"
            // placeholder="select options"
          >
            <InputLabel id="demo-simple-select-label">Race</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="patientRaces"
              defaultValue={() => {
                let newRace;
                const raceArr = Object.values(patientFormField);
                raceArr.forEach((race) => {
                  switch (race) {
                    case "White":
                      newRace = patientFormField.patientRacesWhite;
                      break;
                    case "Black or African American":
                      newRace = patientFormField.patientRacesBlackAmerican;
                      break;
                    case "Native Hawaiian or Pacific Islander":
                      newRace = patientFormField.patientRacesNativeAmerican;
                      break;
                    case "Hispanic or Latino":
                      newRace = patientFormField.patientRacesLatin;
                      break;
                    case "Asian":
                      newRace = patientFormField.patientRacesAsian;
                      break;
                    case "American Indian or Alaskan Native":
                      newRace = patientFormField.patientRacesAlaskin;
                      break;
                    default:
                  }
                });
                return newRace;
              }}
              onChange={handlePatientRaceChange}
            >
              {patientRaces.map((item) => (
                <MenuItem value={item}>{item}</MenuItem>
              ))}
            </Select>
          </FormControl> */}
          {/* <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="latina_or_hispanic"
                  id="patientRacesLatin"
                  Label={{ label: "Hispanic or Latino" }}
                  onChange={handleInputChange}
                  checked={patientFormField.patientRacesLatin}
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="white"
                  id="patientRacesWhite"
                  Label={{ label: "White" }}
                  onChange={handleInputChange}
                  checked={patientFormField.patientRacesWhite}
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="black_african_american"
                  id="patientRacesBlackAmerican"
                  Label={{ label: "Black or African American" }}
                  onChange={handleInputChange}
                  checked={patientFormField.patientRacesBlackAmerican}
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="american_indian_alaskan_native"
                  id="patientRacesNativeAmerican"
                  Label={{ label: "American Indian or Alaskan Native" }}
                  onChange={handleInputChange}
                  checked={patientFormField.patientRacesNativeAmerican}
                />
                <Field
                  component={CheckboxWithLabel}
                  type="checkbox"
                  name="asian"
                  id="patientRacesAsian"
                  Label={{ label: "Asian" }}
                  onChange={handleInputChange}
                  checked={patientFormField.patientRacesAsian}
                /> */}

          <br />
          <Typography variant="h6" className={classes.formTitle}>
            Case Info
          </Typography>
          <div className="helper-wrapper">
            <Typography variant="h6" className={classes.caseHelper}>
              These fields filled by NAF
            </Typography>
          </div>
          <TextField
            id="patientStatus"
            name="patientStatus"
            label="Patient Status"
            className={classes.field}
            // onChange={handleInputChange}
            value={patientFormField.patientStatus || ""}
            // helperText="Read only, not input required."
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
          <TextField
            id="patientNumber"
            name="patientNumber"
            label="Patient Number"
            className={classes.field}
            // onChange={handleInputChange}
            value={patientFormField.patientNumber || ""}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />
          <TextField
            id="patientPledgeNumber"
            name="patientPledgeNumber"
            label="Pledge Number"
            className={classes.field}
            // onChange={handleInputChange}
            value={patientFormField.patientCaseID || ""}
            InputProps={{
              readOnly: true,
            }}
            disabled
          />

          <Typography variant="h6" className={classes.formTitle}>
            Clinic Notes
          </Typography>
          <TextField
            id="patientNotes"
            name="patientNotes"
            label="Notes"
            multiline
            rows={4}
            variant="outlined"
            className={classes.notesText}
            value={patientFormField.patientNotes || ""}
            onChange={handleInputChange}
          />
          {/* <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Submit
                </Button> */}
          <div>{saveButtonState ? submitBtn() : updateBtn()}</div>
        </form>
        {/* </div> */}

        {/* <TextField
          id="outlined-multiline-static"
          label="Notes"
          multiline
          rows={4}
          // defaultValue="Default Value"
          variant="outlined"
          className={classes.notesText}
        /> */}
      </Dialog>
    </div>
  );
};

PatientForm.propTypes = {
  handleClickClose: PropTypes.func,
  openForm: PropTypes.bool,
  patientFormField: PropTypes.object,
  handleInputChange: PropTypes.func,
  handleSaveForm: PropTypes.func,
  handleUpdateForm: PropTypes.func,
  saveButtonState: PropTypes.bool,
  handlePatientRaceChange: PropTypes.func,
  patientRaces: PropTypes.array,
  patientDropDown: PropTypes.array,
  handleMedicalAbortionChange: PropTypes.func,
  handlePatientMedicaidChange: PropTypes.func,
  handlePatientCoPayChange: PropTypes.func,
  handlePatientFundException: PropTypes.func,
  fundExceptionArr: PropTypes.array,
  handleRaceChange: PropTypes.func,
  selectedRaces: PropTypes.array,
  handlePatientRelationshipChange: PropTypes.func,
  relationshipArr: PropTypes.array,
  handlePatientStateChange: PropTypes.func,
  handlePreAbortionServiceApprovedChange: PropTypes.func,

  // error: PropTypes.object,
};

export default PatientForm;
