import React, { useState } from "react";
import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./Profile.scss";
import { conforms } from "lodash";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "16.5rem",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  dialogBox: {
    width: "30rem",
  },
  loggedOutTitle: {
    fontSize: "1rem",
  },
  clearIndicator: {
    // padding: "5rem",
  },
}));

const Profile = ({
  open,
  handleCloseProfile,
  facilities,
  isLoggedIn,
  activeFacility,
  defaultFacility,
  handleChange,
}) => {
  const classes = useStyles();

  const [checked, setChecked] = useState(true);
  const [facilityMatch, setFacilityMatch] = useState("");

  const handleCheckChange = (e) => {
    setChecked(!checked);
  };

  const findMatch = () => {
    let match;
    facilities.forEach((item) => {
      if (item.id.toString() === defaultFacility) {
        match = { id: item.id, name: item.name };
      }
    });
    return match;
  };

  const displayFacilityList = () => {
    return (
      <Autocomplete
        classes={{
          popupIndicator: classes.popUp,
          clearIndicator: classes.clearIndicator,
        }}
        value={activeFacility === "" ? findMatch() : activeFacility}
        id="combo-box-demo"
        options={facilities}
        getOptionLabel={(option) => `ID: ${option.id} NAME: ${option.name}`}
        style={{ width: 400 }}
        renderInput={(params) => (
          <TextField {...params} label="Facilities" variant="outlined" />
        )}
        onChange={handleChange}
      />
    );
  };

  const displayEmptyList = () => {
    const emptyArr = ["none"];

    return (
      <div>
        <Typography
          variant="h5"
          color="textSecondary"
          className={classes.loggedOutTitle}
        >
          * Login required
        </Typography>
        <Autocomplete
          id="combo-box-demo"
          options={emptyArr}
          getOptionLabel={(option) => ` ${option}`}
          style={{ width: 400 }}
          renderInput={(params) => (
            <TextField {...params} label="Facilities" variant="outlined" />
          )}
          onChange={handleChange}
        />
      </div>
    );
  };
  return (
    <div>
      <Dialog
        onClose={handleCloseProfile}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseProfile}>
          Account Settings
        </DialogTitle>
        <DialogContent dividers className={classes.dialogBox}>
          <FormControl className={classes.formControl} variant="filled">
            {/* <InputLabel id="demo-simple-select-label">
              Select Facility
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              // value={facility}
              onChange={handleChange}
            >
              {facilities.map((item) => (
                <MenuItem key={item.id} value={10} className={classes.menuItem}>
                  ID: {item.id} NAME: {item.name}
                </MenuItem>
              ))}

            </Select> */}
            {isLoggedIn.isLoggedIn === true
              ? displayFacilityList()
              : displayEmptyList()}
            {/* <Autocomplete
              id="combo-box-demo"
              options={facilities}
              getOptionLabel={(option) =>
                `ID: ${option.id} NAME: ${option.name}`
              }
              style={{ width: 400 }}
              renderInput={(params) => (
                <TextField {...params} label="Facilities" variant="outlined" />
              )}
            /> */}
            <FormHelperText id="standard-facility-selection-helper-text">
              Switch between facilities
            </FormHelperText>
            {/* <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckChange}
                  name="checkedB"
                  className={classes.checkbox}
                  // color="primary"
                  // iconStyle={{ fill: "#006699" }}
                  style={{
                    color: "#74A2D3",
                  }}
                />
              }
              label="Set as default"
              className={classes.checkboxLabel}
            /> */}
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseProfile} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

Profile.propTypes = {
  open: PropTypes.bool,
  handleCloseProfile: PropTypes.func,
  facilities: PropTypes.array,
  isLoggedIn: PropTypes.object,
  defaultFacility: PropTypes.string,
  handleChange: PropTypes.func,
  activeFacility: PropTypes.object,
};

export default Profile;
