import React, { lazy, useContext } from "react";
import { Switch, Route } from "react-router-dom";

import "./core.scss";

import Header from "./components/Header";

// const Login = lazy(() => import("./containers/Login"));
// const InvitationRegistration = lazy(() =>
//   import("./containers/InvitationRegistration")
// );
// const FacilitySelector = lazy(() => import("./components/FacilitySelector"));
// const Dashboard = lazy(() => import("./containers/Dashboard"));

import Login from "./containers/Login";
import InvitationRegistration from "./containers/InvitationRegistration";
import FacilitySelector from "./components/FacilitySelector";
import Dashboard from "./containers/Dashboard";
import ResetPassword from "./containers/ResetPassword";

const Core = () => {
  return (
    <div>
      <Header />

      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <Route path="/register">
          <InvitationRegistration />
        </Route>
        <Route path="/reset-password">
          <ResetPassword />
        </Route>
        <Route path="/pw_reset">
          <ResetPassword />
        </Route>
        {/* {<Route path="/pw_reset">
          <ResetPassword />
        </Route> ? (
          console.log("hello")
        ) : null} */}
        <Route path="/facility-selection">
          <FacilitySelector />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
      </Switch>
    </div>
  );
};

export default Core;
