import React, { useContext } from "react";
// import { Switch, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthContext } from "./core/libs/AuthContext";
// import CircularProgress from "@material-ui/core/CircularProgress";

import Core from "./core";

function App() {
  const authContext = useContext(AuthContext);

  // const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData);
  // if (userData.token) {
  //   authContext.token = userData.token;
  //   authContext.isLoggedIn = true;
  // }
  // const isLoading = useContext(isLoadingContext);

  // const authenticateUser = () => {
  //   const storedData = JSON.parse(localStorage.getItem("userData"));
  //   const expiration = new Date(new Date().getTime() + 1000 * 60 * 60);
  //   const inactiveTimer = new Date(+new Date() + 1.8e6);
  //   console.log(storedData);

  //   // need to validate against time
  //   if (storedData && storedData.token) {
  //     authContext.token = storedData.token;
  //     authContext.isLoggedIn = true;

  //     localStorage.setItem(
  //       "userData",
  //       JSON.stringify({
  //         token: storedData.token,
  //         expirationTime: expiration.toISOString(),
  //         inactiveTimer: inactiveTimer.toISOString(),

  //         // user: response.data.user_details,
  //         // email: "dennis.rivera@resourcestack.com",
  //         // isLoggedIn: true,
  //       })
  //     );
  //   }
  // };
  // authenticateUser();

  return (
    <div className="App">
      {/* // <Switch>
    //   <Route exact path="/"> */}
      <Router>
        <AuthContext.Provider value={{ isLoggedIn: !!authContext.token }}>
          <Core />
          {/* {isLoading && <CircularProgress />} */}
        </AuthContext.Provider>
      </Router>
      {/* </Route>
    </Switch> */}
    </div>
  );
}

export default App;
