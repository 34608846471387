import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { AuthContext } from "../../libs/AuthContext";

import UploadCases from "../UploadCases/UploadCases";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ImportModal = ({
  handleImportClose,
  importModalState,
  handleUploadMessage,
}) => {
  // const [open, setOpen] = useState(false);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const authContext = useContext(AuthContext);
  return (
    <div>
      <Dialog
        onClose={handleImportClose}
        aria-labelledby="customized-dialog-title"
        open={importModalState}
        // fullWidth={true}
        // maxWidth={"lg"}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleImportClose}>
          Upload Cases
        </DialogTitle>
        <DialogContent dividers>
          <UploadCases
            authContext={authContext}
            handleUploadMessage={handleUploadMessage}
          />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleImportClose} color="primary">
            Save changes
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

ImportModal.propTypes = {
  handleImportClose: PropTypes.func,
  importModalState: PropTypes.bool,
  handleUploadMessage: PropTypes.func,
};

export default ImportModal;
