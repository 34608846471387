export const DEFAULT = {
  BASE_URL: "https://external.prochoice.org/backend/",
  // AUTH_TOKEN: "AhJkypU1ghMtzyRa6N",
};

export const API = {
  GET_PATIENT_API: "get_patient",
  PATIENT_LIST_API: "facility_patients",
  FACILITIES_LIST: "facilities_list",
  CREATE_PATIENT_API: "create_patient",
  UPDATE_PATIENT_API: "update_patient",
  ACCEPT_INVITATION_API: "accept_invitation",
  FIND_INVITED_USER_API: "find_invited_user",
  LOGIN_API: "user_login",
  SINGLE_PATIENT_UPLOAD: "external_single_patient_upload",
  PATIENT_CASE_API: "patient_cases",
  GET_CASE_API: "get_case",
  UPDATE_CASE_API: "update_case",
  CREATE_CASE_API: "create_case",
  FILE_UPLOAD_API: "external_single_patient_upload",
  PASSWORD_RESET_API: "external_user_pw_reset",
  REQUEST_PASSWORD_RESET_API: "request_reset_password",
  FORGOT_PASSWORD_RESET_API: "forgot_password_reset",
  FIND_USER_FOR_PASSWORD_RESET_API: "find_user_for_pw_reset",
  SAVE_DEFAULT_FACILITY_API: "save_default_facility",
  SAVE_PATIENT_CASE_API: "create_patient_and_case",
};
