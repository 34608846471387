import React, { useState, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import "./ResetPassword.scss";
// import NafLogo from "../../../assets/NAF logo.jpg";
import NafLogoSecondary from "../../../assets/MicrosoftTeams-image.png";

import { httpRequest } from "../../core.utils";
import { API } from "../../core.config";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#74A2D3",
    margin: "1rem",
  },
  input: {
    margin: ".5rem 1rem",
    width: "250px",
  },
  btn: {
    width: "12rem",
    backgroundColor: "#74A2D3",
    color: "#ffffff",
    margin: "1rem",
    "&:hover": {
      backgroundColor: "#0085c7",
    },
  },
  loginPrompt: {
    fontSize: ".9rem",
    padding: ".5rem",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ResetPassword = () => {
  const classes = useStyles();

  const [userEmail, setUserEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [resetUserDetails, setResetUserDetails] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const currentPath = useLocation().pathname;
  // if (currentPath === "/pw_reset") {
  //   console.log("It is !");
  // }
  const searchUserToken = useLocation().search;
  const userToken = searchUserToken.replace(/\?/g, "");
  console.log(`User reset password token is ${userToken}`);

  const requestPasswordReset = async () => {
    setIsLoading(true);
    try {
      const response = await httpRequest({
        endpoint: API.REQUEST_PASSWORD_RESET_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            email: userEmail,
          },
        },
      });
      console.log(response.data);
      // console.log(JSON.parse(response));
      setSuccess(true);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setOpenAlert({
        open: true,
        severity: "error",
        message:
          "There was an error sending reset password email. Make sure user spelling is correct and the user exists.",
      });
      console.error("API Call Failed: ", err);
    }
  };

  const handleInputChange = (e) => {
    // console.log(e.target.value);

    setUserEmail(e.target.value);
  };

  const findResetPasswordUser = useCallback(async () => {
    try {
      const response = await httpRequest({
        endpoint: API.FIND_USER_FOR_PASSWORD_RESET_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            token: userToken,
          },
        },
      });
      console.log(response.data);
      setResetUserDetails({
        email: response.data.email,
      });
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  }, [userToken]);

  useEffect(() => {
    if (currentPath === "/pw_reset") {
      findResetPasswordUser();
    }
  }, [currentPath, findResetPasswordUser]);

  const handleResetInputChange = (e) => {
    // console.log(e.target.value);

    setResetUserDetails({
      ...resetUserDetails,
      [e.target.id]: e.target.value,
    });
  };

  const resetUserPassword = async () => {
    setIsLoading(true);
    try {
      const response = await httpRequest({
        endpoint: API.FORGOT_PASSWORD_RESET_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            password: resetUserDetails.password,
            password_confirmation: resetUserDetails.passwordConfirmation,
            email: resetUserDetails.email,
            token: userToken,
          },
        },
      });
      console.log(response.data);
      setIsLoading(false);
      if (response.data.status === "ok") {
        setOpenAlert({
          open: true,
          severity: "success",
          message: "Password successfully changed. Return to log in.",
        });
      } else {
        setOpenAlert({
          open: true,
          severity: "warning",
          message: "Reset token may have expired.",
        });
      }

      // setSuccess(true);
    } catch (err) {
      console.error("API Call Failed: ", err);
      setIsLoading(false);
      setOpenAlert({
        open: true,
        severity: "error",
        message:
          "There was an error resetting the account password. Make sure user spelling is correct and the user exists.",
      });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert({
      open: false,
      severity: "",
      message: "",
    });
  };

  const returnSuccessMessage = () => {
    return (
      <div className="request-reset-container">
        <div>
          <Paper square>
            <div className="reset-wrapper">
              <div>
                {/* <img src={NafLogo} alt="Naf logo" className="naf-logo" /> */}
                <img
                  src={NafLogoSecondary}
                  alt="Naf logo"
                  className="naf-logo"
                />
              </div>
              <Typography
                variant="h5"
                color="textSecondary"
                className={classes.title}
              >
                Forgot Password Email Sent
              </Typography>
              <Typography
                variant="h5"
                color="textSecondary"
                className={classes.title}
              >
                Your request for a password reset was successfully sent to{" "}
                {userEmail}.
              </Typography>
              <div>
                <Button
                  type="submit"
                  variant="contained"
                  // color="secondary"
                  size="large"
                  className={classes.btn}
                  onClick={() => {
                    setSuccess(false);
                  }}
                >
                  Resend Email
                </Button>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.loginPrompt}
                >
                  or{" "}
                  <Link to="/">
                    <span className="login-link"> Return to Login</span>
                  </Link>
                </Typography>
              </div>
            </div>
          </Paper>
        </div>
      </div>
    );
  };

  const returnDefault = () => {
    if (isLoading !== true) {
      return (
        <div className="request-reset-container">
          <div>
            <Paper square>
              <div className="reset-wrapper">
                <div>
                  {/* <img src={NafLogo} alt="Naf logo" className="naf-logo" /> */}
                  <img
                    src={NafLogoSecondary}
                    alt="Naf logo"
                    className="naf-logo"
                  />
                </div>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  className={classes.title}
                >
                  Forgot Password
                </Typography>
                <TextField
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email ID"
                  type="email"
                  className={classes.input}
                  onChange={handleInputChange}
                  value={userEmail}
                ></TextField>
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    // color="secondary"
                    size="large"
                    className={classes.btn}
                    onClick={requestPasswordReset}
                  >
                    Reset Password
                  </Button>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.loginPrompt}
                  >
                    or{" "}
                    <Link to="/">
                      <span className="login-link">Login</span>
                    </Link>
                  </Typography>
                </div>
              </div>
            </Paper>
            <Snackbar
              open={openAlert.open}
              autoHideDuration={5000}
              onClose={handleCloseAlert}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <Alert severity={openAlert.severity} onClose={handleCloseAlert}>
                {openAlert.message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      );
    } else {
      return (
        <div className="request-reset-container">
          <div>
            <Paper square>
              <div className="reset-wrapper">
                <div>
                  {/* <img src={NafLogo} alt="Naf logo" className="naf-logo" /> */}
                  <img
                    src={NafLogoSecondary}
                    alt="Naf logo"
                    className="naf-logo"
                  />
                </div>
                <Typography
                  variant="h5"
                  color="textSecondary"
                  className={classes.title}
                >
                  Forgot Password
                </Typography>
                <CircularProgress />
                <div>
                  <Button
                    type="submit"
                    variant="contained"
                    // color="secondary"
                    size="large"
                    className={classes.btn}
                    onClick={requestPasswordReset}
                  >
                    Reset Password
                  </Button>
                  <Typography
                    variant="h6"
                    color="textSecondary"
                    className={classes.loginPrompt}
                  >
                    or{" "}
                    <Link to="/">
                      <span className="login-link">Login</span>
                    </Link>
                  </Typography>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      );
    }
  };

  const returnResetPasswordPage = () => {
    return (
      <div className="request-reset-container">
        <div>
          <Paper square>
            <div className="reset-wrapper">
              <div>
                {/* <img src={NafLogo} alt="Naf logo" className="naf-logo" /> */}
                <img
                  src={NafLogoSecondary}
                  alt="Naf logo"
                  className="naf-logo"
                />
              </div>
              <Typography
                variant="h5"
                color="textSecondary"
                className={classes.title}
              >
                Forgot Password
              </Typography>
              <div className="input-container">
                <TextField
                  variant="outlined"
                  id="email"
                  name="email"
                  label="Email ID"
                  type="email"
                  className={classes.input}
                  helperText="Active user email"
                  // onChange={handleResetInputChange}
                  value={resetUserDetails.email}
                ></TextField>
                <TextField
                  variant="outlined"
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  className={classes.input}
                  helperText="Set new password"
                  onChange={handleResetInputChange}

                  // value={userEmail}
                ></TextField>
                <TextField
                  variant="outlined"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  label="Password Confirmation"
                  type="password"
                  className={classes.input}
                  helperText="Confirm new password"
                  onChange={handleResetInputChange}
                  // value={userEmail}
                ></TextField>
              </div>

              <div>
                {isLoading !== true ? (
                  <Button
                    type="submit"
                    variant="contained"
                    // color="secondary"
                    size="large"
                    className={classes.btn}
                    onClick={resetUserPassword}
                  >
                    Reset Password
                  </Button>
                ) : (
                  <CircularProgress />
                )}

                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.loginPrompt}
                >
                  or{" "}
                  <Link to="/">
                    <span className="login-link">Login</span>
                  </Link>
                </Typography>
              </div>
            </div>
          </Paper>
          <Snackbar
            open={openAlert.open}
            autoHideDuration={5000}
            onClose={handleCloseAlert}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
          >
            <Alert severity={openAlert.severity} onClose={handleCloseAlert}>
              {openAlert.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  };

  return currentPath === "/pw_reset"
    ? returnResetPasswordPage()
    : success === true
    ? returnSuccessMessage()
    : returnDefault();
};

export default ResetPassword;
