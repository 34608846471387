import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import TextField from "@material-ui/core/TextField";

import { httpRequest } from "../../core.utils";
import { API } from "../../core.config";
import { AuthContext } from "../../libs/AuthContext";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles((theme) => ({
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  //   width: "16.5rem",
  // },
  // selectEmpty: {
  //   marginTop: theme.spacing(2),
  // },
  // dialogBox: {
  //   width: "30rem",
  // },
  // loggedOutTitle: {
  //   fontSize: "1rem",
  // },
  dialogBox: {
    textAlign: "center",
  },
  formTitle: {
    color: "#74A2D3",
  },
  field: {
    margin: ".5rem 1rem",
    width: "250px",
  },
}));

const UserSettings = ({
  openSettings,
  handleCloseSettings,
  setOpenSettings,
}) => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  // const notificationAlert = useContext(notificationAlertContext);

  const [userDetails, setUserDetails] = useState({
    // email: authContext.email,
    password: "",
    passwordConfirmation: "",
    // token: authContext.token,
  });

  // console.log(userDetails);

  const handleResetPassword = useCallback(async () => {
    try {
      const response = await httpRequest({
        endpoint: API.PASSWORD_RESET_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            password_confirmation: userDetails.passwordConfirmation,
            password: userDetails.password,
            email: authContext.email,
          },
          email: authContext.email,
        },
        params: {
          email: authContext.email,
          password: userDetails.password,
          password_confirmation: userDetails.passwordConfirmation,
        },
      });

      console.log(response.data);
      setOpenSettings(false);
      // setUserDetails({
      //   recipientEmail: response.data.user_details.user_email,
      //   facility: response.data.user_details.facility,
      // });
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  }, [
    authContext.email,
    userDetails.password,
    userDetails.passwordConfirmation,
    setOpenSettings,
  ]);

  // useEffect(() => {
  //   findPasswordResetUser();
  // }, [findPasswordResetUser]);

  const handleInputChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <div>
      <Dialog
        // fullWidth={true}
        maxWidth="md"
        onClose={handleCloseSettings}
        aria-labelledby="customized-dialog-title"
        open={openSettings}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseSettings}>
          Account Settings
        </DialogTitle>
        <DialogContent dividers className={classes.dialogBox}>
          <Formik>
            {(props) => (
              <Form className={classes.resetPasswordForm}>
                {/* <h1>{userToken2}</h1> */}

                <Typography className={classes.formTitle}>
                  Reset Password
                </Typography>

                {/* <Typography variant="h6" className={classes.formHelperText}>
                  Requires email invitation for complete registration
                </Typography> */}

                <Field
                  id="email"
                  component={TextField}
                  name="email"
                  label="Email ID"
                  className={classes.field}
                  // onChange={handleInputChange}
                  variant="outlined"
                  value={authContext.email}
                  type="email"
                />
                <br />

                <Field
                  id="password"
                  component={TextField}
                  name="password"
                  label="Password"
                  className={classes.field}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={userDetails.password}
                  type="password"
                />
                <br />
                <Field
                  id="passwordConfirmation"
                  component={TextField}
                  name="passwordConfirmation"
                  label="Password Confirmation"
                  className={classes.field}
                  onChange={handleInputChange}
                  variant="outlined"
                  value={userDetails.passwordConfirmation}
                  type="password"
                />
                <br />
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleResetPassword} color="primary">
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

UserSettings.propTypes = {
  openSettings: PropTypes.bool,
  handleCloseSettings: PropTypes.func,
  setOpenSettings: PropTypes.func,
};

export default UserSettings;
