import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import { AuthContext } from "../../libs/AuthContext";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// import Header from "../../components/Header";
import "./Login.scss";
// import NafLogo from "../../../assets/NAF logo.jpg";
import NafLogoSecondary from "../../../assets/MicrosoftTeams-image.png";

import { API, DEFAULT } from "../../core.config";

//https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/

const useStyles = makeStyles((theme) => ({
  // margin: {
  //   margin: theme.spacing(1),
  // },
  textField: {
    width: "250px",
    margin: theme.spacing(1),
  },
  loginBtn: {
    width: "250px",
    backgroundColor: "#74a2d3",
    color: "#ffffff",
  },
  loginTitle: {
    color: "#74a2d3",
  },
  loginInvitationPrompt: {
    fontSize: ".9rem",
    padding: "1rem",
  },
  loginInvitationLink: {
    color: "#74A2D3",
  },
  loginText: {
    fontSize: "1rem",
  },
  resetPasswordPrompt: {
    fontSize: ".9rem",
    padding: ".5rem",
  },
  resetPasswordLink: {
    color: "#74A2D3",
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Login = () => {
  const classes = useStyles();
  const authContext = useContext(AuthContext);
  const history = useHistory();
  // console.log("[login auth context]", authContext);
  // console.log("[login local storage]", localStorage);
  const [passwordError, setPasswordError] = useState(false);
  const [values, setValues] = useState({
    password: "",
    email: "",
    showPassword: false,
  });
  const [loginMessage, setLoginMessage] = useState({});
  const [loginStatus, setLoginStatus] = useState();
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });

  // const handleChange = (prop) => (event) => {
  //   setValues({ ...values, [prop]: event.target.value });
  // };
  let errorArray = [];
  const oneLowerCase = new RegExp("^(?=.*[a-z])");
  const oneUpperCase = new RegExp("^(?=.*[A-Z])");
  const oneNumber = new RegExp("^(?=.*[0-9])");
  const oneSpecial = new RegExp("^(?=.*[!@#$%^&*])");
  const strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );

  //* least 8 characters, a number, and a special character
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    //onchange handle of password verfication
    if (prop === "password") {
      setPasswordError(!strongRegex.test(event.target.value));
    }
    //indicates what characters are missing
    if (passwordError === true && prop === "password") {
      alertUserOfPasswordError(event.target.value);
      document.getElementById("passwordErrorRep").innerHTML =
        errorArray.join(", ");
    } else {
      document.getElementById("passwordErrorRep").innerHTML = "";
    }
  };
  //alerts user of specific errors. Should only fire when a user
  //is creating an account? and AFTER they hit login.
  const alertUserOfPasswordError = (value) => {
    if (oneLowerCase.test(value) === false) {
      errorArray.push("Missing lower case");
    }
    if (oneUpperCase.test(value) === false) {
      errorArray.push("Missing upper case");
    }
    if (oneNumber.test(value) === false) {
      errorArray.push("Missing number");
    }
    if (oneSpecial.test(value) === false) {
      errorArray.push("Missing special case");
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //temporary
  const handleAdminLogin = (e) => {
    const data = {
      data: {
        email: values.email,
        password: values.password,
      },
    };
    axios.post(DEFAULT.BASE_URL + API.LOGIN_API, data).then((response) => {
      console.log(response.data.user_details);
      const expiration = new Date(new Date().getTime() + 1000 * 60 * 60);
      const inactiveTimer = new Date(+new Date() + 1.8e6);
      if (
        response.data.status === "ok" &&
        response.data.message === "Logged in"
      ) {
        authContext.token = response.data.user_details.user_token;
        authContext.isLoggedIn = true;
        authContext.email = response.data.user_details.user_email;
        authContext.defaultFacility =
          response.data.user_details.default_facility;

        localStorage.setItem(
          "userData",
          JSON.stringify({
            token: response.data.user_details.user_token,
            expirationTime: expiration.toISOString(),
            inactiveTimer: inactiveTimer.toISOString(),
            // user: response.data.user_details,
            email: response.data.user_details.user_email,
            isLoggedIn: true,
            defaultFacility: response.data.user_details.default_facility,
          })
        );

        history.push("/dashboard");
      } else {
        e.preventDefault();
        setLoginMessage(response.data.message);
        setLoginStatus(response.data.status);
        setOpenAlert({
          open: true,
          severity: "error",
          message: `${response.data.message} Make sure email and password are correct.`,
        });
        // console.log("[login auth context]", authContext);
        // console.log("[login local storage]", localStorage);
        console.log(response.data.message);
      }
    });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert({
      open: false,
      severity: "",
      message: "",
    });
  };

  return (
    <div className="login-container">
      <div className="page-content">
        <Paper square>
          <div>
            {/* <img src={NafLogo} alt="Naf logo" className="naf-logo" /> */}
            <img src={NafLogoSecondary} alt="Naf logo" className="naf-logo" />
          </div>
          <div className="page-header">
            <Typography
              variant="h5"
              color="textSecondary"
              className={classes.loginTitle}
            >
              Login
            </Typography>
          </div>
          <div className="login-form">
            <div className="input-row">
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="standard-email">Email ID</InputLabel>
                <OutlinedInput
                  id="standard-email"
                  type="text"
                  value={values.email}
                  onChange={handleChange("email")}
                  labelWidth={60}
                />
              </FormControl>
            </div>
            <div className="input-row">
              <FormControl className={classes.textField} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  error={passwordError}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={70}
                />
              </FormControl>
            </div>
            <div className="input-row">
              <p id="passwordErrorRep"> </p>
            </div>
            <div className="login-btn">
              <Button
                variant="contained"
                // color="secondary"
                size="large"
                className={classes.loginBtn}
                onClick={handleAdminLogin}
              >
                Login
              </Button>
            </div>
            <Typography
              variant="h6"
              color="textSecondary"
              className={classes.resetPasswordPrompt}
            >
              <Link to="/reset-password">
                <span className={classes.resetPasswordLink}>
                  Forgot your password?
                </span>
              </Link>
            </Typography>
            {/* <Typography
              variant="h6"
              color="textSecondary"
              className={classes.loginInvitationPrompt}
            >
              New?{" "}
              <Link to="/register">
                <span className={classes.loginInvitationLink}>
                  Sign up with an invitation.
                </span>
              </Link>
            </Typography> */}
          </div>
        </Paper>
        <Snackbar
          open={openAlert.open}
          autoHideDuration={5000}
          onClose={handleCloseAlert}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Alert severity={openAlert.severity} onClose={handleCloseAlert}>
            {openAlert.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
};

export default Login;
