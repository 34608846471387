import { createContext } from "react";

export const AuthContext = createContext({
  isLoggedIn: false,
  token: null,
  email: "",
  defaultFacility: "",
});

//set token expiration here

// export const FacilityContext = createContext({
//   activeFacility: 9804,
// });

// export const isLoadingContext = createContext({
//   isLoading: false,
// });

// export const notificationAlertContext = createContext({
//   open: false,
//   message: "",
//   severity: "",
// });
