import React, { useState, useEffect, useCallback, useContext } from "react";
import DashboardTable from "../../components/DashboardTable";
import PatientForm from "../../components/PatientForm";
import ImportModal from "../../components/ImportModal";
import Profile from "../../components/Profile";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ApartmentIcon from "@material-ui/icons/Apartment";
// import Autocomplete from "@material-ui/lab/Autocomplete";
// import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

// import moment from "moment";

import "./Dashboard.scss";

import { httpRequest } from "../../core.utils";
import { API } from "../../core.config";
import { AuthContext } from "../../libs/AuthContext";
import { Redirect } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  dashboardSubmittedTitle: {
    color: "#74A2D3",
  },
  addPatientBtn: {
    margin: "0 2rem",
    backgroundColor: "#74A2D3",
    borderRadius: "3rem",
    color: "#ffffff",
  },
  addPatientIcon: {
    color: "#ffffff",
  },
  // textField: {
  //   marginLeft: theme.spacing(1),
  //   marginRight: theme.spacing(1),
  //   width: 200,
  // },
  // root: {
  //   boxShadow: "5px 10px 8px #888888",
  //   position: "fixed",
  //   margin: "10rem 5rem 0 5rem",
  //   zIndex: 1,
  //   width: "75%",
  // },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Dashboard = () => {
  const classes = useStyles();

  const facilityContext = useContext(AuthContext);
  const userFromLS = localStorage.getItem("userData");
  const parsedLS = JSON.parse(userFromLS);
  const authContext = parsedLS;
  if (authContext.isLoggedIn === true) {
    facilityContext.isLoggedIn = true;
  }
  // if (!authContext) {
  //   const userFromLS = localStorage.getItem("userData");
  //   if (userFromLS) {
  //     authContext = JSON.parse(userFromLS);
  //     console.log(authContext);
  //   }
  // }
  // console.log(
  //   "[dashboard local storage]",
  //   JSON.parse(localStorage.getItem("userData"))
  // );
  // const userData = JSON.parse(localStorage.getItem("userData"));
  // console.log(userData.expirationTime);
  // console.log(moment().format("YYYY-MM-DD[T]HH:mm:ss.SSS[Z]"));
  // console.log("[Auth Context]", authContext);
  //  const notificationAlert = useContext(notificationAlertContext);
  //  console.log(notificationAlert);

  const [open, setOpen] = useState(false);
  const [assignedPatientList, setAssignedPatientList] = useState([]);
  const [notAssignedPatientList, setNotAssignedPatientList] = useState([]);
  const [saveButtonState, setSaveButtonState] = useState(false);
  const [importModalState, setImportModalState] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activeFacility, setActiveFacility] = useState("");

  // const [race, setRace] = useState(" ");

  // const [patientId, setPatientId] = useState();
  // const [patientDetails, setPatientDetails] = useState({});

  const [patient, setPatient] = useState({
    patientCaseID: "",
    patient_id: "",
    facilityIdNumber: "",
    patientFirstName: "",
    patientLastName: "",
    patientResidenceZipcode: "",
    patientPhoneNumber: "",
    patientCity: "",
    patientStateOfResidence: "",
    patientFamilySize: "",
    patientGrossMonthly: "",
    patientApptWksLmp: "",
    patientDateOfAbortionDate: "",
    patientProcedurePrice: "",
    patientContributionNumber: "",
    patientTotalOtherFunds: "",
    patientOtherFundName: "",
    patientAmountOfPledge: "",
    patientCopayException: "",
    patientExceptionApproval: "",
    patientFundException: "",
    patientPreabortionApproved: "",
    patientPreAbortionAmount: "",
    patientRelationshipStatus: "",
    patientGestage: "",
    medicalAbortion: "",
    patientOnMediciad: "",
    patientRace: "Race",
    patientStatus: "",
    patientNumber: "",
    patientPledgeNumber: "",
    patientRaces: [
      "Asian",
      "Native Hawaiian or Pacific Islander",
      "Black or African American",
      "White",
      "Hispanic or Latino",
      "American Indian or Alaskan Native",
      "Other",
    ],
    patientRacesAsian: "",
    patientRacesNativeAmerican: "",
    patientRacesBlackAmerican: "",
    patientRacesWhite: "",
    patientRacesLatin: "",
    patientRacesAlaskin: "",
    patientRacesOther: "",

    patientNotes: "",
    patient_pre_abortion_services_amt: "",
    patient_pre_abortion_services_approved: false,
  });

  const [selectedRaces, setSelectedRaces] = useState([]);

  // const [patientRace, setPatientRace] = useState();

  // const handleChange = (e, value, option) => {
  //   if (value === null || value.id === null) {
  //     setSelectedFacility(facilities[0].id);
  //   } else {
  //     setSelectedFacility(value.id);
  //   }

  //   // console.log(e.target.name);
  //   // console.log(value.id);
  //   // console.log(option);
  // };

  const handleClickOpen = () => {
    setSaveButtonState(true);
    setOpen(true);
    setPatient({
      patient_id: "",
      facilityIdNumber:
        selectedFacility === "" ? facilities[0].id : selectedFacility,
      patientFirstName: "",
      patientLastName: "",
      patientResidenceZipcode: "",
      patientPhoneNumber: "",
      patientCity: "",
      patientStateOfResidence: "",
      patientFamilySize: "",
      patientGrossMonthly: "",
      patientApptWksLmp: "",
      patientDateOfAbortionDate: "",
      patientProcedurePrice: "",
      patientContributionNumber: "",
      patientTotalOtherFunds: "",
      patientOtherFundName: "",
      patientAmountOfPledge: "",
      patientCopayException: "",
      patientExceptionApproval: "",
      patientFundException: "",
      patientPreabortionApproved: "",
      patientPreAbortionAmount: "",
      patientRelationshipStatus: "",
      patientGestage: "",
      medicalAbortion: "",
      patientOnMediciad: "",
      patientRace: "Race",
      patientStatus: "",
      patientNumber: "",
      patientPledgeNumber: "",
      patientRaces: "",
      patientRacesAsian: "",
      patientRacesNativeAmerican: "",
      patientRacesBlackAmerican: "",
      patientRacesWhite: "",
      patientRacesLatin: "",
      patientRacesAlaskin: "",
      patientRacesOther: "",

      patientNotes: "",
      patient_pre_abortion_services_amt: "",
      patient_pre_abortion_services_approved: false,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const emptyField = "/^s+$/";

  const handleInputChange = (e) => {
    // if (e.target.value.match(emptyField)) {
    //   setError({ error: true, errorText: "*Missing field" });
    // } else {
    //   setError({ error: false, errorText: "" });
    // }
    setPatient({
      ...patient,
      [e.target.id]: e.target.value,
    });
  };

  const fetchFacilitiesList = useCallback(async () => {
    try {
      const response = await httpRequest({
        endpoint: API.FACILITIES_LIST,
        method: "POST",
        // headers: {},
        data: {
          data: {
            email: authContext.email,
          },
        },
        params: {
          email: authContext.email,
        },
      });
      setFacilities(response.data.facilities_list);
      // console.log(response.data.facilities_list);
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  }, [authContext.email]);

  useEffect(() => {
    fetchFacilitiesList();
  }, [fetchFacilitiesList]);

  // console.log(facilities[0].id);

  const fetchPatientList = useCallback(
    async (tempFacility) => {
      setIsLoading(true);
      try {
        const response = await httpRequest({
          endpoint: API.PATIENT_LIST_API,
          method: "POST",
          // headers: {},
          data: {
            data: {
              facility_id:
                selectedFacility === ""
                  ? authContext.defaultFacility
                  : selectedFacility,

              // facility_id: 9395,
              email: authContext.email,
            },
          },
          params: {
            email: authContext.email,
            // facility_id: 9395,

            facility_id:
              selectedFacility === ""
                ? authContext.defaultFacility
                : selectedFacility,
          },
        });
        const patientList = response.data.patients_list;
        const notAssignedToClinc = patientList.filter(
          (item) => item.assigned_to_clinic !== true
        );
        setNotAssignedPatientList(notAssignedToClinc);
        const assignedToClinc = patientList.filter(
          (item) => item.assigned_to_clinic === true
        );
        setAssignedPatientList(assignedToClinc);
        // console.log(response.data.patients_list);
        // console.log(selectedFacility);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);

        console.error("API Call Failed: ", err);
      }
    },
    [authContext.email, selectedFacility, authContext.defaultFacility]
  );

  useEffect(() => {
    if (facilities.length > 0) fetchPatientList();
  }, [fetchPatientList, facilities]);

  const handleChangeDefaultFacility = async (id) => {
    try {
      await httpRequest({
        endpoint: API.SAVE_DEFAULT_FACILITY_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            default_facility: id,
            email: authContext.email,
          },
        },
        params: {
          default_facility: id,
          email: authContext.email,
        },
      });
      // console.log(response.data);
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  };

  const handleOpenProfile = () => {
    setOpenProfile(true);
  };
  const handleFacilityChange = (e, value, option) => {
    setSelectedFacility(value.id);
    setActiveFacility(value);
    handleChangeDefaultFacility(value.id);
  };

  const handleCloseProfile = () => {
    setOpenProfile(false);
    fetchPatientList();
  };

  // useEffect(() => {
  const fetchPatientData = async (patientId) => {
    try {
      const response = await httpRequest({
        endpoint: API.GET_PATIENT_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            patient_id: patientId,
            email: authContext.email,
          },
        },
        params: {
          patient_id: patientId,
          email: authContext.email,
        },
      });
      // console.log(response.data);

      const caseResponse = await httpRequest({
        endpoint: API.PATIENT_CASE_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            email: authContext.email,
            patient_id: patientId,
          },
        },
        params: {
          email: authContext.email,
          patient_id: patientId,
        },
      });

      setPatient({
        patientCaseID: caseResponse.data.case_details[0].case_id,
        patient_id: patientId,
        facilityIdNumber: caseResponse.data.case_details[0].facility_id,
        patientFirstName: response.data.patients_details.patient_firstname,
        patientLastName: response.data.patients_details.patient_lastname,
        patientResidenceZipcode:
          response.data.patients_details.residence_zipcode,
        patientPhoneNumber: response.data.patients_details.patient_phone,
        patientCity: response.data.patients_details.city,
        patientStateOfResidence:
          response.data.patients_details.state_of_residence,
        patientFamilySize: caseResponse.data.case_details[0].fam_size,
        patientGrossMonthly:
          caseResponse.data.case_details[0].gross_monthly.toString(),
        patientDateOfAbortionNumber: response.data.patients_details.date_case,
        patientDateOfAbortionDate:
          caseResponse.data.case_details[0].date_of_abortion,
        patientProcedurePrice:
          caseResponse.data.case_details[0].procedure_price.toString(),
        patientContributionNumber:
          caseResponse.data.case_details[0].amount_patient_has === null ?  "0" : caseResponse.data.case_details[0].amount_patient_has.toString(),
        patientTotalOtherFunds:
          caseResponse.data.case_details[0].total_other_funds === null ? "0" : caseResponse.data.case_details[0].total_other_funds.toString(),
        patientOtherFundName: caseResponse.data.case_details[0].other_fund_name,
        patientAmountOfPledge:
          caseResponse.data.case_details[0].amount_of_pledge === null ? "0" : caseResponse.data.case_details[0].amount_of_pledge.toString(),
        patientCopayException:
          caseResponse.data.case_details[0].copay_exception === false
            ? "No"
            : "Yes",
        patientExceptionApproval:
          caseResponse.data.case_details[0].copay_exception_approval,
        patientFundException: caseResponse.data.case_details[0].fund_exception,
        patientGestage: caseResponse.data.case_details[0].gestage,
        patientApptWksLmp: caseResponse.data.case_details[0].appt_wks_lmp,
        patientPreabortionApproved: "",
        patientPreAbortionAmount: "",
        patientRelationshipStatus: response.data.patients_details.relationship,
        medicalAbortion:
          response.data.patients_details.medical_abortion === true
            ? "Yes"
            : "No",
        patientOnMediciad:
          response.data.patients_details.pt_on_medicaid_bool === true
            ? "Yes"
            : "No",
        patientRace: "",
        patientStatus: caseResponse.data.case_details[0].status,
        patientNumber: caseResponse.data.case_details[0].patient_id,
        patientPledgeNumber: caseResponse.data.case_details[0].pledge_number,
        patientRaces: "",
        patientRacesAsian: response.data.patients_details.asian,
        patientRacesNativeAmerican:
          response.data.patients_details.native_hawaiian_pacific_islander,
        patientRacesBlackAmerican:
          response.data.patients_details.black_african_american,
        patientRacesWhite: response.data.patients_details.white,
        patientRacesLatin: response.data.patients_details.latina_or_hispanic,
        patientRacesAlaskin:
          response.data.patients_details.american_indian_alaskan_native,
        patientRacesOther: response.data.patients_details.other_race,

        patientNotes: caseResponse.data.case_details[0].notes,
        patient_pre_abortion_services_amt:
          caseResponse.data.case_details[0].sono_amt !== null &&
          caseResponse.data.case_details[0].sono_amt.toString(),
        patient_pre_abortion_services_approved:
          caseResponse.data.case_details[0].sono_funded,
      });

      // console.log(caseResponse.data);

      //
      // handleRaceSelected();

      // setPatientDetails(response.data.patients_details);
      // console.log(response.data.patients_details);
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  };

  const onHandleNameClick = (e) => {
    // setPatientId(e.target.id);
    fetchPatientData(e.target.id);
    // console.log(e.target.id);
    // fetchPatientCaseData(e.target.id);
    setSaveButtonState(false);
    setOpen(true);
  };

  const createPatientData = async () => {
    // if (typeof error !== "undefined") {
    // const validationPassed = validateForm();
    // if (validationPassed === false) {
    //   return;
    // }
    // // }

    // console.log("ok");

    try {
      const response = await httpRequest({
        endpoint: API.SAVE_PATIENT_CASE_API,
        method: "POST",
        // headers: {},
        data: {
          email: authContext.email,

          data: {
            patient_number: patient.patientPhoneNumber,
            patient_firstname: patient.patientFirstName,
            patient_lastname: patient.patientLastName,
            patient_phone: patient.patientPhoneNumber,
            residence_zipcode: patient.patientResidenceZipcode,
            city: patient.patientCity,
            state_of_residence: patient.patientStateOfResidence,
            relationship: patient.patientRelationshipStatus,
            asian: patient.patientRacesAsian,
            native_hawaiian_pacific_islander:
              patient.patientRacesNativeAmerican,
            black_african_american: patient.patientRacesBlackAmerican,
            white: patient.patientRacesWhite,
            latina_or_hispanic: patient.patientRacesLatin,
            american_indian_alaskan_native: patient.patientRacesAlaskin,
            other_race: patient.patientRacesOther,
            facility_id: patient.facilityIdNumber,
            fam_size: patient.patientFamilySize,
            date_of_abortion: patient.patientDateOfAbortionDate,
            gross_monthly: patient.patientGrossMonthly,
            procedure_price: patient.patientProcedurePrice,
            amount_patient_has: patient.patientContributionNumber,
            total_other_funds: patient.patientTotalOtherFunds,
            other_fund_name: patient.patientOtherFundName,
            amount_of_pledge: patient.patientAmountOfPledge,
            copay_exception:
              patient.patientCopayException === "Yes" ? true : false,
            copay_exception_approval: patient.patientExceptionApproval,
            fund_exception: patient.patientFundException,
            gestage: patient.patientGestage,
            appt_wks_lmp: patient.patientApptWksLmp,
            medical_abortion: patient.medicalAbortion === "Yes" ? true : false,
            pt_on_medicaid_bool:
              patient.patientOnMediciad === "Yes" ? true : false,
            patient_id: patient.patientNumber,
            pledge_number: patient.patientPledgeNumber,
            notes: patient.patientNotes,
            status: patient.patientStatus,
            sono_amt: patient.patient_pre_abortion_services_amt,
            sono_funded: patient.patient_pre_abortion_services_approved,
          },
        },
      });
      // setPatientFields(response.data.patients_details);
      fetchPatientList();
      setOpen(false);
      // console.log(response.data);

      if (response.data.status === "ok") {
        setOpenAlert({
          open: true,
          severity: "success",
          message: "Patient submitted!",
        });
      }
    } catch (err) {
      console.error("API Call Failed: ", err);
      setOpenAlert({
        open: true,
        severity: "error",
        message: "Patient submit failed.",
      });
    }
  };

  const updatePatientData = async () => {
    try {
      const response = await httpRequest({
        endpoint: API.UPDATE_PATIENT_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            patient_id: patient.patient_id,
            patient_number: patient.patientPhoneNumber,
            patient_firstname: patient.patientFirstName,
            patient_lastname: patient.patientLastName,
            patient_phone: patient.patientPhoneNumber,
            residence_zipcode: patient.patientResidenceZipcode,
            city: patient.patientCity,
            state_of_residence: patient.patientStateOfResidence,
            date_case: patient.patientDateOfAbortionDate,
            relationship: patient.patientRelationshipStatus,
            medical_abortion: patient.medicalAbortion === "Yes" ? true : false,
            pt_on_medicaid_bool:
              patient.patientOnMediciad === "Yes" ? true : false,
            asian: patient.patientRacesAsian,
            native_hawaiian_pacific_islander:
              patient.patientRacesNativeAmerican,
            black_african_american: patient.patientRacesBlackAmerican,
            white: patient.patientRacesWhite,
            latina_or_hispanic: patient.patientRacesLatin,
            american_indian_alaskan_native: patient.patientRacesAlaskin,
            other_race: patient.patientRacesOther,
            email: authContext.email,
          },
        },
        params: {
          patient_id: patient.patient_id,
          patient_number: patient.patientPhoneNumber,
          patient_firstname: patient.patientFirstName,
          patient_lastname: patient.patientLastName,
          patient_phone: patient.patientPhoneNumber,
          residence_zipcode: patient.patientResidenceZipcode,
          city: patient.patientCity,
          state_of_residence: patient.patientStateOfResidence,
          date_case: patient.patientDateOfAbortionDate,
          relationship: patient.patientRelationshipStatus,
          medical_abortion: patient.medicalAbortion === "Yes" ? true : false,
          pt_on_medicaid_bool:
            patient.patientOnMediciad === "Yes" ? true : false,
          asian: patient.patientRacesAsian,
          native_hawaiian_pacific_islander: patient.patientRacesNativeAmerican,
          black_african_american: patient.patientRacesBlackAmerican,
          white: patient.patientRacesWhite,
          latina_or_hispanic: patient.patientRacesLatin,
          american_indian_alaskan_native: patient.patientRacesAlaskin,
          other_race: patient.patientRacesOther,
          email: authContext.email,
        },
      });
      // setPatientFields(response.data.patients_details);

      // console.log(response.data);

      const caseResponse = await httpRequest({
        endpoint: API.UPDATE_CASE_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            email: authContext.email,
            reason: "",
            case_id: patient.patientCaseID,
            facility_id: patient.facilityIdNumber,
            fam_size: patient.patientFamilySize,
            gross_monthly: patient.patientGrossMonthly,
            procedure_price: patient.patientProcedurePrice,
            amount_patient_has: patient.patientContributionNumber,
            total_other_funds: patient.patientTotalOtherFunds,
            other_fund_name: patient.patientOtherFundName,
            amount_of_pledge: patient.patientAmountOfPledge,
            copay_exception:
              patient.patientCopayException === "Yes" ? true : false,
            copay_exception_approval: patient.patientExceptionApproval,
            fund_exception: patient.patientFundException,
            gestage: patient.patientGestage,
            appt_wks_lmp: patient.patientApptWksLmp,
            // on_medicaid: patient.patientOnMediciad === "Yes" ? true : false,
            patient_id: patient.patientNumber,
            pledge_number: patient.patientPledgeNumber,
            date_of_abortion: patient.patientDateOfAbortionDate,
            notes: patient.patientNotes,
            status: patient.patientStatus,
            sono_amt: patient.patient_pre_abortion_services_amt,
            sono_funded: patient.patient_pre_abortion_services_approved,
          },
        },
        params: {
          email: authContext.email,
          reason: "",
          case_id: patient.patientCaseID,
          facility_id: patient.facilityIdNumber,
          fam_size: patient.patientFamilySize,
          gross_monthly: patient.patientGrossMonthly,
          procedure_price: patient.patientProcedurePrice,
          amount_patient_has: patient.patientContributionNumber,
          total_other_funds: patient.patientTotalOtherFunds,
          other_fund_name: patient.patientOtherFundName,
          amount_of_pledge: patient.patientAmountOfPledge,
          copay_exception:
            patient.patientCopayException === "Yes" ? true : false,
          copay_exception_approval: patient.patientExceptionApproval,
          fund_exception: patient.patientFundException,
          gestage: patient.patientGestage,
          appt_wks_lmp: patient.patientApptWksLmp,
          // on_medicaid: patient.patientOnMediciad === "Yes" ? true : false,
          patient_id: patient.patientNumber,
          pledge_number: patient.patientPledgeNumber,
          date_of_abortion: patient.patientDateOfAbortionDate,
          notes: patient.patientNotes,
          status: patient.patientStatus,
          sono_amt: patient.patient_pre_abortion_services_amt,
          sono_funded: patient.patient_pre_abortion_services_approved,
        },
      });

      // console.log(caseResponse.data);

      if (caseResponse.data.status === "ok" && response.data.status === "ok") {
        fetchPatientList();
        setOpen(false);
        setOpenAlert({
          open: true,
          severity: "success",
          message: "Patient updated!",
        });
      }
    } catch (err) {
      console.error("API Call Failed: ", err);
      setOpenAlert({
        open: true,
        severity: "error",
        message: "Patient update failed.",
      });
    }
  };

  const handleImportOpen = () => {
    setImportModalState(true);
  };
  const handleImportClose = () => {
    setImportModalState(false);
  };

  const handleUploadMessage = (param) => {
    // console.log(param);
    setImportModalState(false);
    fetchFacilitiesList();
    if (param.failed_count === 0) {
      setOpenAlert({
        open: true,
        severity: "success",
        message: param.message,
      });
    } else {
      // const errArr = param.error_message_array;
      // let errMessage;
      // let errTest = {
      //   error_message_array: [
      //     "Row (1): You can not assign patients to facility id 9929.0 ",
      //     "Row (2): You can not assign patients to facility id 9929.0 ",
      //     "Row (3): You can not assign patients to facility id 9929.0 ",
      //   ],
      //   failed_count: 1,
      //   message: [],
      //   status: ["error"],
      //   success_count: 0,
      // };
      // var newText = text.replace(/\n/g, "<br>");
      setOpenAlert({
        open: true,
        severity: "error",
        message: (
          <div>
            <h3>
              Success: {param.success_count} number of records successfully
              saved.
            </h3>
            <h3>
              Failed: {param.failed_count} number of records had the following
              errors, preventing upload.
            </h3>

            {param.error_message_array.map((item) => {
              return (
                <div>
                  <div>{item}</div>
                </div>
              );
            })}
          </div>
        ),
      });
      // setOpenAlert({
      //   open: true,
      //   severity: "error",
      //   message: errTest.error_message_array.join().replace(/!/g, "<br>"),
      // });
      // setOpenAlert({
      //   open: true,
      //   severity: "error",
      //   message: errArr.join(),
      // });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert({
      open: false,
      severity: "",
      message: "",
    });
  };

  // const patientRaces = [
  //   "American Indian or Alaskan Native",
  //   "Asian",
  //   "Black or African American",
  //   "Hispanic or Latinx",
  //   "Native Hawaiian or Pacific Islander",
  //   "White",
  //   "Other",
  // ];

  const patientDropDown = ["Yes", "No"];

  const handleMedicalAbortionChange = (e) => {
    // console.log(e.target.value);

    setPatient({
      ...patient,
      medicalAbortion: e.target.value,
    });
  };

  const handlePatientMedicaidChange = (e) => {
    // console.log(e.target.value);

    setPatient({
      ...patient,
      patientOnMediciad: e.target.value,
    });
  };

  const handlePatientCoPayChange = (e) => {
    const coPay = e.target.value;

    switch (coPay) {
      case "Yes":
        setPatient({
          ...patient,
          patientCopayException: true,
        });
        break;
      case "No":
        setPatient({
          ...patient,
          patientCopayException: false,
        });
        break;
      default:
    }
    setPatient({
      ...patient,
      patientCopayException: e.target.value,
    });
  };

  const fundExceptionArr = [
    "Gestational Age",
    "Ineligible for Medicaid",
    "Student Hardship",
    "Federal Health Program",
    "Other",
  ];

  const handlePatientFundException = (e) => {
    // console.log(e.target.value);

    setPatient({
      ...patient,
      patientFundException: e.target.value,
    });
  };

  const handleRaceChange = (e, value, option) => {
    // console.log(e.target.value);
    // console.log(value);
    // console.log(option);

    setSelectedRaces(value);
    let raceObj = {
      patientRacesAsian: "",
      patientRacesNativeAmerican: "",
      patientRacesBlackAmerican: "",
      patientRacesWhite: "",
      patientRacesLatin: "",
      patientRacesAlaskin: "",
      patientRacesOther: "",
    };
    value.forEach((race) => {
      switch (race) {
        case "White":
          raceObj = {
            ...raceObj,
            patientRacesWhite: true,
          };

          break;
        case "Black or African American":
          // newRace = patient.patientRacesBlackAmerican;
          raceObj = {
            ...raceObj,
            patientRacesBlackAmerican: true,
          };
          break;
        case "Native Hawaiian or Pacific Islander":
          raceObj = {
            ...raceObj,
            patientRacesNativeAmerican: true,
          };
          break;
        case "Hispanic or Latinx":
          raceObj = {
            ...raceObj,
            patientRacesLatin: "1",
          };
          break;
        case "Asian":
          raceObj = {
            ...raceObj,
            patientRacesAsian: true,
          };
          break;
        case "American Indian or Alaskan Native":
          raceObj = {
            ...raceObj,
            patientRacesAlaskin: true,
          };
          break;

        case "Other":
          raceObj = {
            ...raceObj,
            patientRacesOther: true,
          };
          break;
        default:
      }
    });
    setPatient({
      ...patient,
      patientRacesAsian: raceObj.patientRacesAsian,
      patientRacesNativeAmerican: raceObj.patientRacesNativeAmerican,
      patientRacesBlackAmerican: raceObj.patientRacesBlackAmerican,
      patientRacesWhite: raceObj.patientRacesWhite,
      patientRacesLatin: raceObj.patientRacesLatin,
      patientRacesAlaskin: raceObj.patientRacesAlaskin,
      patientRacesOther: raceObj.patientRacesOther,
    });
  };
  // console.log(patient);

  const relationshipArr = [
    "Married",
    "Lives with partner but not married",
    "Never Married and not living with partner",
    "Divorced/Widowed/Separated and not living with partner",
  ];

  const handlePatientRelationshipChange = (e) => {
    setPatient({
      ...patient,
      patientRelationshipStatus: e.target.value,
    });
  };

  const handlePreAbortionServiceApprovedChange = (e) => {
    // console.log("***** for pre abort service change")
    // console.log(e.target.id)
    // console.log(e.target.checked)
    setPatient({
      ...patient,
      patient_pre_abortion_services_approved: e.target.checked,
    });
  };

  const handlePatientStateChange = (e) => {
    setPatient({
      ...patient,
      patientStateOfResidence: e.target.value,
    });
  };

  if (authContext.isLoggedIn === true) {
    return (
      <div>
        <div className="dashboard-content">
          {isLoading === true ? (
            <div className="spinner-content">
              <CircularProgress />
            </div>
          ) : null}

          <div className="dashboard-import">
            <Button
              variant="contained"
              // color="secondary"
              size="large"
              endIcon={<ApartmentIcon />}
              className={classes.addPatientBtn}
              onClick={handleOpenProfile}
            >
              Manage Facility
            </Button>
            <Button
              variant="contained"
              // color="secondary"
              size="large"
              endIcon={
                <AddCircleOutlineIcon className={classes.addPatientIcon} />
              }
              className={classes.addPatientBtn}
              onClick={handleClickOpen}
            >
              Submit Patient
            </Button>
            <Button
              variant="contained"
              // color="secondary"
              size="large"
              endIcon={<ImportExportIcon />}
              className={classes.addPatientBtn}
              onClick={handleImportOpen}
            >
              Intake Import
            </Button>
          </div>
          {/* <div className="dashboard-list">
            <Autocomplete
              id="combo-box-demo"
              options={facilities}
              getOptionLabel={(option) =>
                `ID: ${option.id} NAME: ${option.name}`
              }
              style={{ width: 400 }}
              renderInput={(params) => (
                <TextField {...params} label="Facilities" variant="outlined" />
              )}
              onChange={handleChange}
            />
          </div> */}

          <div className="dashboard-submitted-wrap">
            <Typography
              variant="h5"
              color="textSecondary"
              className={classes.dashboardSubmittedTitle}
            >
              PATIENTS SUBMITTED BY CLINIC
            </Typography>
          </div>
          <DashboardTable
            notAssignedPatientList={notAssignedPatientList}
            assignedPatientList={assignedPatientList}
            onHandleNameClick={onHandleNameClick}
            isLoading={isLoading}
          />
          <PatientForm
            openForm={open}
            handleClickClose={handleClose}
            handleInputChange={handleInputChange}
            patientFormField={patient}
            handleSaveForm={createPatientData}
            handleUpdateForm={updatePatientData}
            saveButtonState={saveButtonState}
            // handlePatientRaceChange={handlePatientRaceChange}
            // patientRaces={patientRaces}
            patientDropDown={patientDropDown}
            handlePatientMedicaidChange={handlePatientMedicaidChange}
            handlePatientCoPayChange={handlePatientCoPayChange}
            fundExceptionArr={fundExceptionArr}
            handlePatientFundException={handlePatientFundException}
            handleRaceChange={handleRaceChange}
            selectedRaces={selectedRaces}
            relationshipArr={relationshipArr}
            handlePatientRelationshipChange={handlePatientRelationshipChange}
            handlePatientStateChange={handlePatientStateChange}
            handlePreAbortionServiceApprovedChange={
              handlePreAbortionServiceApprovedChange
            }
            // error={error}
            // race={race}
          />
          <ImportModal
            importModalState={importModalState}
            handleImportClose={handleImportClose}
            handleUploadMessage={handleUploadMessage}
          />
          <Profile
            open={openProfile}
            handleCloseProfile={handleCloseProfile}
            facilities={facilities}
            activeFacility={activeFacility}
            isLoggedIn={authContext}
            defaultFacility={authContext.defaultFacility}
            handleChange={handleFacilityChange}
          />
          <Snackbar
            open={openAlert.open}
            autoHideDuration={1000000}
            onClose={handleCloseAlert}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert severity={openAlert.severity} onClose={handleCloseAlert}>
              {openAlert.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  } else {
    return <Redirect to="/" />;
  }
};

export default Dashboard;
