import React, { Component } from "react";
import "./UploadCases.scss";
import Dropzone from "./Dropzone/Dropzone";
import Progress from "./Progress/Progress";
import { API, DEFAULT } from "../../core.config";

class UploadCases extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      uploadMessage: {},
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
  }

  onFilesAdded(files) {
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }));
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div className="ProgressWrapper">
          <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
          <img
            className="CheckIcon"
            alt="done"
            src="baseline-check_circle_outline-24px.svg"
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0,
            }}
          />
        </div>
      );
    }
  }

  renderActions(authContext) {
    if (this.state.successfullUploaded) {
      return (
        <button
          // className="button"
          variant="contained"
          // color="secondary"
          size="large"
          className="uploadBtn button"
          onClick={() =>
            this.setState({ files: [], successfullUploaded: false })
          }
        >
          Clear
        </button>
      );
    } else {
      return (
        <button
          variant="contained"
          // color="secondary"
          size="large"
          className="uploadBtn button"
          disabled={this.state.files.length < 0 || this.state.uploading}
          onClick={() => this.uploadFiles(authContext)}
        >
          Upload
        </button>
      );
    }
  }

  async uploadFiles(authContext) {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.files.forEach((file) => {
      promises.push(this.sendRequest(file, authContext));
    });
    try {
      await Promise.all(promises);

      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      // Not Production ready! Do some error handling here instead...
      this.setState({ successfullUploaded: true, uploading: false });
    }
  }

  sendRequest(file, authContext) {
    return new Promise((resolve, reject) => {
      let req = new XMLHttpRequest();
      const handleUploadMessage = this.props.handleUploadMessage;

      // progress management
      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
        resolve(req.response);
      });

      req.upload.addEventListener("error", (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      //upload
      let formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("email", authContext.email);

      req.onreadystatechange = function () {
        if (req.readyState === XMLHttpRequest.DONE) {
          const resObj = JSON.parse(req.responseText.replace(/\\n/g, ""));
          console.log(resObj);
          handleUploadMessage(resObj);
        }
      };

      req.open("POST", DEFAULT.BASE_URL + API.FILE_UPLOAD_API);
      req.setRequestHeader("user-token", authContext.token);
      req.send(formData);
    });
  }

  render() {
    const userFromLS = localStorage.getItem("userData");
    const parsedLS = JSON.parse(userFromLS);
    const authContext = parsedLS || this.props.authContext;

    return (
      <div className="Upload">
        {/* <span className="Title">Upload Cases</span> */}
        <div className="Content">
          {/* <div> */}
          <Dropzone
            onFilesAdded={this.onFilesAdded}
            disabled={this.state.uploading || this.state.successfullUploaded}
          />
          {/* </div> */}

          <div className="Files">
            {this.state.files.map((file) => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>

        <div className="Actions">{this.renderActions(authContext)}</div>
      </div>
    );
  }
}

export default UploadCases;
