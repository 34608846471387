import React, { useState, useCallback, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import LockIcon from "@material-ui/icons/Lock";
import * as Yup from "yup";

import "./InvitationRegistration.scss";
import { makeStyles } from "@material-ui/core/styles";

import { httpRequest } from "../../core.utils";
import { API } from "../../core.config";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { AuthContext } from "../../libs/AuthContext";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#006699",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  patientFormToolbar: {
    backgroundColor: "#006699",
  },
  registrationForm: {
    textAlign: "center",
    // padding: "1rem",
  },
  formTitle: {
    color: "#74A2D3",
    margin: "1rem",
  },
  formHelperText: {
    fontSize: ".7rem",
    margin: "0 1rem",
  },
  outlinedInput: {
    margin: "0 .5rem",
  },
  notesText: {
    width: "50%",
    margin: "1rem",
  },
  formBtn: {
    width: "12rem",
    backgroundColor: "#006699",
    color: "#ffffff",
    margin: "1rem",
    "&:hover": {
      backgroundColor: "#0085c7",
    },
  },
  field: {
    margin: ".5rem 1rem",
    width: "250px",
  },
  registerBtn: {
    width: "250px",
    backgroundColor: "#74A2D3",
    color: "#ffffff",
    margin: "1rem 0",
  },
  noAccessTitle: {
    color: "#74A2D3",
    margin: "1rem",
    textAlign: "center",
  },
  lockIcon: {
    fontSize: "3rem",
  },
  errorText: {
    fontSize: "1rem",
  },
}));

const registerSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "User name is to short. Must be a minimum of 2 characters.")
    .max(50, "User name is to long. Must be a maximum of 50 characters.")
    .required("Name is required"),

  initials: Yup.string()
    .min(2, "Too Short.")
    .max(50, "Too Long.")
    .required("Name is required"),

  // recipientEmail: Yup.string()
  //   .email("Invalid email.")
  //   .required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum"),

  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match.")
    .required("You must re-type your password."),
});

const InvitationRegistration = () => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  const history = useHistory();

  const [userDetails, setUserDetails] = useState({
    facility: "",
    name: "",
    initials: "",
    recipientEmail: "",
    password: "",
    passwordConfirmation: "",
    token: "",
    position: "",
  });

  const [registrationStatus, setRegistrationStatus] = useState();
  const [registrationMessage, setRegistrationMessage] = useState({});

  // const { userToken } = useParams();
  // let userData = localStorage.getItem("userData");
  // console.log(userData);
  // if (userData) {
  //   headers["user-token"] = userData["token"];
  // }

  const searchUserToken = useLocation().search;
  const userToken = searchUserToken.replace(/\?/g, "");
  console.log(`User registration token is ${userToken}`);

  const handleInputChange = (e) => {
    // console.log(e.target.id);

    setUserDetails({
      ...userDetails,
      [e.target.id]: e.target.value,
    });
  };

  const checkUser = async (e) => {
    e.preventDefault();
    let formData = {
      name: e.target[2].value,
      initials: e.target[4].value,
      password: e.target[8].value,
      passwordConfirmation: e.target[10].value,
    };

    // console.log(formData);
    const isValid = await registerSchema.isValid(formData);
    // console.log(isValid);

    // registerSchema
    //   .validate(formData)
    //   .then((res) => {
    //     const message = res.data;
    //     // resolve(this.createError({ message: message }));
    //     console.log(message);
    //   })
    //   .catch((errors) => {
    //     const schemaErrors = errors.inner.map((err) => {
    //       return { field: err.path, message: err.message };
    //     });
    //     console.log(schemaErrors);
    //   });

    await registerSchema.validate(formData).catch(function (err) {
      console.log(err.name); // => 'ValidationError'
      console.log(err.errors); // => ['Deve ser maior que 18']
    });
  };

  // const handleLoginRedirect = async (email, password) => {
  //   try {
  //     const response = await httpRequest({
  //       endpoint: API.LOGIN_API,
  //       method: "POST",
  //       // headers: {},
  //       data: {
  //         data: {
  //           email: email,
  //           password: password,
  //         },
  //       },
  //       params: {
  //         email: email,
  //         password: password,
  //       },
  //     });
  //     console.log(response);
  //     const expiration = new Date(new Date().getTime() + 1000 * 60 * 60);
  //     const inactiveTimer = new Date(+new Date() + 1.8e6);
  //     authContext.token = response.data.user_details.user_token;
  //     authContext.isLoggedIn = true;
  //     authContext.email = response.data.user_details.user_email;

  //     localStorage.setItem(
  //       "userData",
  //       JSON.stringify({
  //         token: authContext.token,
  //         expirationTime: expiration.toISOString(),
  //         inactiveTimer: inactiveTimer.toISOString(),
  //       })
  //     );

  //     history.push("/dashboard");
  //   } catch (err) {
  //     console.error("API Call Failed: ", err);
  //   }
  // };

  const findUser = useCallback(async () => {
    try {
      const response = await httpRequest({
        endpoint: API.FIND_INVITED_USER_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            token: userToken,
          },
        },
        params: {
          token: userToken,
        },
      });
      console.log(response.data.user_details);
      setUserDetails({
        recipientEmail: response.data.user_details.user_email,
        facility: response.data.user_details.facility,
      });
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  }, [userToken]);

  useEffect(() => {
    localStorage.setItem(
      "userData",
      JSON.stringify({
        token: userToken,
      })
    );
    findUser();
  }, [findUser, userToken]);

  const registerUser = async () => {
    try {
      const response = await httpRequest({
        endpoint: API.ACCEPT_INVITATION_API,
        method: "POST",
        // headers: {},
        data: {
          data: {
            recipient_email: userDetails.recipientEmail,
            token: userToken,
            password: userDetails.password,
            password_confirmation: userDetails.passwordConfirmation,
            position: "External User",
            name: userDetails.name,
            initials: userDetails.initials,
            facility: userDetails.facility,
          },
        },
        params: {
          recipient_email: userDetails.recipientEmail,
          token: userToken,
          password: userDetails.password,
          password_confirmation: userDetails.passwordConfirmation,
          position: "External User",
          name: userDetails.name,
          initials: userDetails.initials,
          facility: userDetails.facility,
        },
      });

      if (response.data.status === "bad_request") {
        setRegistrationStatus(response.data.status);
        setRegistrationMessage(response.data.message);
      }

      // console.log(response.data.user_details);
      const expiration = new Date(new Date().getTime() + 1000 * 60 * 60);
      const inactiveTimer = new Date(+new Date() + 1.8e6);
      authContext.token = response.data.user_details.user_token;
      authContext.isLoggedIn = true;
      authContext.email = response.data.user_details.user_email;

      localStorage.setItem(
        "userData",
        JSON.stringify({
          token: authContext.token,
          expirationTime: expiration.toISOString(),
          inactiveTimer: inactiveTimer.toISOString(),
        })
      );

      history.push("/dashboard");
      // handleLoginRedirect(
      //   response.data.user_details.user_email,
      //   response.data.user_details
      // );
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  };

  // console.log(registrationMessage);

  if (searchUserToken !== "") {
    return (
      <div>
        <div>
          <Paper square>
            {registrationStatus === "bad_request" ? (
              <div className="error-container">
                {Object.entries(registrationMessage).map(([key, value]) => (
                  <Typography
                    variant="h5"
                    color="textSecondary"
                    className={classes.errorText}
                    key={key}
                  >
                    {key}: {value.toString()}
                  </Typography>
                ))}
              </div>
            ) : null}

            <Formik
              initialValues={{
                facility: "",
                name: "",
                initials: "",
                recipientEmail: "",
                password: "",
                passwordConfirmation: "",
              }}
              validationSchema={registerSchema}
              // validate={(values) => {
              //   const errors = {};
              //   if (!values.email) {
              //     errors.email = "Required";
              //   } else if (
              //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
              //   ) {
              //     errors.email = "Invalid email address";
              //   }
              //   return errors;
              // }}
              // onSubmit={(values, { setSubmitting }) => {
              //   setTimeout(() => {
              //     setSubmitting(false);
              //     alert(JSON.stringify(values, null, 2));
              //   }, 500);
              //   console.log(values);
              //   registerUser();
              // }}
            >
              {(props) => (
                <Form className={classes.registrationForm} onSubmit={checkUser}>
                  {/* <h1>{userToken2}</h1> */}

                  <Typography variant="h6" className={classes.formTitle}>
                    Register
                  </Typography>
                  <Typography variant="h6" className={classes.formHelperText}>
                    Requires email invitation for complete registration
                  </Typography>
                  <Field
                    id="facility"
                    component={TextField}
                    name="facility"
                    label="Facility"
                    className={classes.field}
                    // onChange={handleInputChange}
                    variant="outlined"
                    value={userDetails.facility}
                  />
                  <br />

                  <Field
                    id="name"
                    component={TextField}
                    name="name"
                    label="First and Last Name "
                    className={classes.field}
                    onChange={handleInputChange}
                    variant="outlined"
                    // value={patientFormField.facilityId}
                  />
                  <br />
                  <Field
                    id="initials"
                    component={TextField}
                    name="initials"
                    label="Initials"
                    className={classes.field}
                    onChange={handleInputChange}
                    variant="outlined"
                    // value={patientFormField.lastName}
                  />
                  <br />
                  <Field
                    id="recipientEmail"
                    component={TextField}
                    name="recipientEmail"
                    label="Email ID"
                    className={classes.field}
                    // onChange={handleInputChange}
                    variant="outlined"
                    value={userDetails.recipientEmail}
                    type="email"
                  />
                  <br />

                  <Field
                    id="password"
                    component={TextField}
                    name="password"
                    label="Password"
                    className={classes.field}
                    onChange={handleInputChange}
                    variant="outlined"
                    // value={patientFormField.firstName}
                    type="password"
                  />
                  <br />
                  <Field
                    id="passwordConfirmation"
                    component={TextField}
                    name="passwordConfirmation"
                    label="Password Confirmation"
                    className={classes.field}
                    onChange={handleInputChange}
                    variant="outlined"
                    // value={patientFormField.lastName}
                    type="password"
                  />
                  <br />
                  {/* <Field
                  id="position"
                  component={TextField}
                  name="position"
                  label="Position"
                  className={classes.field}
                  onChange={handleInputChange}
                  variant="outlined"
                  // value={patientFormField.lastName}
                />
                <br />

                <Field
                  id="accessToken"
                  component={TextField}
                  name="accessToken"
                  label="Access Token"
                  className={classes.field}
                  onChange={handleInputChange}
                  variant="outlined"

                  // value={patientFormField.state}
                /> */}

                  {/* {isSubmitting && <LinearProgress />} */}

                  <div className="login-btn">
                    <Button
                      type="submit"
                      variant="contained"
                      // color="secondary"
                      size="large"
                      className={classes.registerBtn}
                      // disabled={isSubmitting}
                      onClick={registerUser}
                    >
                      Register
                    </Button>
                  </div>
                  {/* <Button
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={submitForm}
                >
                  Submit
                </Button> */}
                </Form>
              )}
            </Formik>
          </Paper>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <Paper square>
            <Typography variant="h6" className={classes.noAccessTitle}>
              The registration page can only be reached through an email
              invitation with a user access token.
            </Typography>
            <div className="lock-icon-container">
              <LockIcon className={classes.lockIcon} />
            </div>
          </Paper>
        </div>
      </div>
    );
  }
};

export default InvitationRegistration;
