import React, { useState, useCallback, useEffect, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./FacilitySelector.scss";
import { httpRequest } from "../../core.utils";
import { API } from "../../core.config";
import { AuthContext } from "../../libs/AuthContext";

const useStyles = makeStyles((theme) => ({
  facilityFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "25rem",
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    backgroundColor: "#ffffff",
    // width: "40rem",
    // left: "200px",
  },
  facilitySelectionTitle: {
    color: "#006699",
  },
  facilitySelectionBtn: {
    backgroundColor: "#006699",
    color: "#ffffff",
    margin: "1rem",
  },
  checkboxLabel: {
    color: "#006699",
  },
  checkbox: {
    color: "#006699",
    // backgroundColor: "#006699",
  },
}));

const FacilitySelector = () => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  // const facilityContext = useContext(FacilityContext);

  // const [facility, setFacility] = useState("");
  const [checked, setChecked] = useState(true);
  const [facilities, setFacilities] = useState([]);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };
  // const handleChange = (event) => {
  //   setFacility(event.target.value);
  // };

  const setFacility = (e) => {
    console.log(e.target.innerText);
  };

  // const handleContinueButton = () => {
  //   facilityContext.activeFacility =

  // }

  // const userDetails = JSON.parse(localStorage.getItem("userData"));
  // console.log(userDetails);

  const fetchFacilitiesList = useCallback(async () => {
    try {
      const response = await httpRequest({
        endpoint: API.FACILITIES_LIST,
        method: "POST",
        // headers: {},
        data: {
          data: {
            email: authContext.email,
          },
        },
        params: {
          email: authContext.email,
        },
      });
      setFacilities(response.data.facilities_list);
      console.log(response.data.facilities_list);
    } catch (err) {
      console.error("API Call Failed: ", err);
    }
  }, [authContext.email]);

  useEffect(() => {
    fetchFacilitiesList();
  }, [fetchFacilitiesList]);

  return (
    <div className="facility-container">
      <div className="facility-content">
        <Paper square>
          <div className="paper-header">
            <Typography
              variant="h5"
              color="textSecondary"
              className={classes.facilitySelectionTitle}
            >
              Select Facility
            </Typography>
          </div>
          <div className="formcontrol-wrapper">
            {/* <FormControl
              variant="filled"
              className={classes.facilityFormControl}
            >
              <InputLabel id="demo-simple-select-filled-label">
                Facility
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={facility}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {facilities.map((item) => (
                  <MenuItem value={item.id} className={classes.menuItem}>
                    ID: {item.id} NAME: {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            <Autocomplete
              id="combo-box-demo"
              options={facilities}
              getOptionLabel={(option) =>
                `ID: ${option.id} NAME: ${option.name}`
              }
              style={{ width: 400 }}
              renderInput={(params) => (
                <TextField {...params} label="Facilities" variant="outlined" />
              )}
              onChange={setFacility}
            />

            <Button
              variant="contained"
              // color="secondary"
              size="large"
              className={classes.facilitySelectionBtn}
              // onClick={handleContinueButton}
            >
              CONTINUE
            </Button>
          </div>

          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheckChange}
                name="checkedB"
                className={classes.checkbox}
                // color="primary"
                // iconStyle={{ fill: "#006699" }}
                style={{
                  color: "#006699",
                }}
              />
            }
            label="Set as default"
            className={classes.checkboxLabel}
          />
        </Paper>
      </div>
    </div>
  );
};

export default FacilitySelector;
