import axios from "axios";
// import _get from "lodash/get";
import { DEFAULT } from "./core.config";

export const httpRequest = (
  apiConfig = {
    method: "get",
    endpoint: null,
    headers: {},
    data: null,
    params: {},
  }
) =>
  new Promise((resolve, reject) => {
    let headers = {
      "content-type": "application/json",
      // "user-token": DEFAULT.AUTH_TOKEN,
      "user-token": "",
    };
    if (typeof localStorage["userData"] !== "undefined") {
      let userData = JSON.parse(localStorage["userData"]);
      // console.log(userData);
      if (userData) {
        headers["user-token"] = userData["token"];
      }
    }

    axios({
      method: apiConfig.method,
      url: DEFAULT.BASE_URL + apiConfig.endpoint,
      headers,
      data: apiConfig.data,
      params: apiConfig.params,
    }).then(
      (res) => {
        resolve(res);
      },
      (err) => {
        reject(err.response);
      }
    );
  });
