import React, { useState, useContext } from "react";
// import Profile from "../Profile";
import UserSettings from "../UserSettings";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

// import { httpRequest } from "../../core.utils";
// import { API } from "../../core.config";
import { AuthContext } from "../../libs/AuthContext";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  appBar: {
    // backgroundColor: "#006699",
    backgroundColor: "#74A2D3",
    color: "#ffffff",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

const Header = () => {
  const classes = useStyles();

  const authContext = useContext(AuthContext);
  // console.log(authContext);

  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [openSettings, setOpenSettings] = useState(false);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpenSettings = () => {
    setOpenSettings(true);
  };
  const handleCloseSettings = () => {
    setOpenSettings(false);
  };

  // console.log(localStorage);

  // const userDetails = JSON.parse(localStorage.getItem("userData"));

  const logoutHandler = () => {
    localStorage.removeItem("userData");
    authContext.isLoggedIn = false;
    history.push("/");
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            NAF HOTLINE
          </Typography>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
          >
            {authContext.isLoggedIn ? (
              <div>
                <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                <Link to="/dashboard">
                  <MenuItem onClick={handleClose}>Dashboard</MenuItem>
                </Link>
                <MenuItem onClick={handleOpenSettings}>Settings</MenuItem>
              </div>
            ) : (
              <Link to="/">
                <MenuItem onClick={handleClose}>Login</MenuItem>
              </Link>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      <UserSettings
        openSettings={openSettings}
        handleCloseSettings={handleCloseSettings}
        setOpenSettings={setOpenSettings}
      />
      {/* <Profile
        open={openProfile}
        handleCloseProfile={handleCloseProfile}
        facilities={facilities}
        isLoggedIn={authContext}
        selectedFacility={setSelectedFacility}
      /> */}
    </div>
  );
};

export default Header;
